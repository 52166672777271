// COMPANY
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const SET_COMPANY = 'SET_COMPANY';

// PROPERTY
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_PROPERTIES_PER_PAGE = 'SET_PROPERTIES_PER_PAGE';
export const FETCH_PROPERTY_INFO = 'FETCH_PROPERTY_INFO';
export const SET_VISIBLE_PROPERTIES = 'SET_VISIBLE_PROPERTIES';
export const CLEAR_PROPERTY = 'CLEAR_PROPERTY';
export const FILTER_PROPERTIES = 'FILTER_PROPERTIES';
export const FETCH_PROPERTIES_FOR_COMPANY = 'FETCH_PROPERTIES_FOR_COMPANY';
export const FETCH_PROPERTIES_RECENT = 'FETCH_PROPERTIES_RECENT';
export const FLUSH_PROPERTIES = 'FLUSH_PROPERTIES';

// SCHEME
export const CLEAR_SCHEME = 'CLEAR_SCHEME';
export const FETCH_SCHEME = 'FETCH_SCHEME';
export const FETCH_SCHEME_AVAILABILITY = 'FETCH_SCHEME_AVAILABILITY';
export const FETCH_SCHEME_ERROR = 'FETCH_SCHEME_ERROR';
export const FETCH_SCHEMES_FOR_COMPANY = 'FETCH_SCHEMES_FOR_COMPANY';
export const FILTER_SCHEMES = 'FILTER_PROPERTIES';
export const SEARCH_SCHEME = 'SEARCH_SCHEME';
export const SEARCH_SCHEME_CLEAR = 'SEARCH_SCHEME_CLEAR';
export const SEARCH_SCHEME_RESULTS = 'SEARCH_SCHEME_RESULTS';
export const SET_ACTIVE_SCHEME_TAB = 'SET_ACTIVE_SCHEME_TAB';
export const SET_SCHEME = 'SET_SCHEME';
export const SET_SCHEME_VIEW_PAGE = 'SET_SCHEME_VIEW_PAGE';
export const SET_SCHEME_AVAILABILITY = 'SET_SCHEME_AVAILABILITY';
export const SET_SCHEMES = 'SET_SCHEMES';
export const SET_SCHEMES_PER_PAGE = 'SET_SCHEMES_PER_PAGE';
export const SET_VISIBLE_SCHEMES = 'SET_VISIBLE_SCHEMES';

// SCHEME_NEW
export const SET_SCHEMES_NEW = 'SET_SCHEMES_NEW';
export const SET_SCHEMES_FILTER = 'SET_SCHEMES_FILTER';
export const SET_SCHEMES_VIEW = 'SET_SCHEMES_VIEW';
export const SET_SCHEMES_VIEW_PAGE = 'SET_SCHEMES_VIEW_PAGE';
export const FETCH_SCHEMES_OUTLINE_START = 'FETCH_SCHEMES_OUTLINE_START';
export const FETCH_SCHEMES_OUTLINE_CHUNK = 'FETCH_SCHEMES_OUTLINE_CHUNK';
export const FETCH_SCHEMES_OUTLINE_COMPLETE = 'FETCH_SCHEMES_OUTLINE_COMPLETE';
export const APPLY_SCHEMES_FILTERS = 'APPLY_SCHEMES_FILTERS';
export const UPDATE_SCHEMES_VIEW = 'UPDATE_SCHEMES_VIEW';
export const RESET_SCHEMES_FILTERS = 'RESET_SCHEMES_FILTERS';
export const SET_VISIBLE_CATEGORY = 'SET_VISIBLE_CATEGORY';
export const FLUSH_SCHEMES = 'FLUSH_SCHEMES';


// REP
export const APPLY_REPS_FILTERS = 'APPLY_REPS_FILTERS';
export const FETCH_REP = 'FETCH_REP';
export const FETCH_REPS = 'FETCH_REPS';
export const FLUSH_REPS = 'FLUSH_REPS';
export const RESET_REPS_FILTERS = 'RESET_REPS_FILTERS';
export const SET_REP = 'SET_REP';
export const SET_REPS = 'SET_REPS';
export const SET_REPS_FILTER = 'SET_REPS_FILTER';
export const SET_REPS_PER_PAGE = 'SET_REPS_PER_PAGE';
export const SET_REPS_VIEW = 'SET_REPS_VIEW';
export const SET_REPS_VIEW_PAGE = 'SET_REPS_VIEW_PAGE';
export const UPDATE_REPS_VIEW = 'UPDATE_REPS_VIEW';

// BLOG
export const FETCH_POSTS = 'FETCH_POSTS';
export const SET_POSTS = 'SET_POSTS';

// SEARCH
export const SET_LOCATION_BOUNDS = 'SET_LOCATION_BOUNDS';
export const SET_EXTENDED_BOUNDS = 'SET_EXTENDED_BOUNDS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

// AUTH
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REMEMBER_TOGGLE = 'AUTH_REMEMBER_TOGGLE';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_PENDING = 'AUTH_RESET_PASSWORD_PENDING';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR';
export const AUTH_SET_TOKENS = 'AUTH_SET_TOKENS';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const REMOVE_USER = 'REMOVE_USER';

// VIEW
export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_VIEW_PAGE = 'SET_VIEW_PAGE';
export const SET_PROPERTY_VIEW_PAGE = 'SET_PROPERTY_VIEW_PAGE';
export const SET_VIEW_PAGES = 'SET_VIEW_PAGES';
export const SET_VIEW_TAB = 'SET_VIEW_TAB';
export const SET_VIEW = 'SET_VIEW';
export const SET_PROPERTY_VIEW = 'SET_PROPERTY_VIEW';
export const SET_SCHEME_VIEW = 'SET_SCHEME_VIEW';
export const SET_ACTIVE_PROPERTY_TAB = 'SET_ACTIVE_PROPERTY_TAB';
export const TOGGLE_SHOW_MAP = 'TOGGLE_SHOW_MAP';
export const HIGHLIGHT_MAP_MARKER = 'HIGHLIGHT_MAP_MARKER';
export const CLEAR_MAP_MARKER = 'CLEAR_MAP_MARKER';
export const PAUSE_VIDEO = 'PAUSE_VIDEO';
export const SET_FILTER_TO_SHOW = 'SET_FILTER_TO_SHOW';

// FILTER
export const FILTER_AVAILABILITY = 'FILTER_AVAILABILITY';
export const FILTER_KEYWORD = 'FILTER_KEYWORD';
export const FILTER_MAX_SIZE = 'FILTER_MAX_SIZE';
export const FILTER_MAX_RENT = 'FILTER_MAX_RENT';
export const FILTER_NULL = 'FILTER_NULL';
export const FILTER_RESET = 'FILTER_RESET';
export const FILTER_BY_PARAMS = 'FILTER_BY_PARAMS';
export const SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS';
export const FILTER_UPDATE = 'FILTER_UPDATE';

// COMPARE
export const COMPARE_LIST_ADD = 'COMPARE_LIST_ADD';
export const COMPARE_LIST_REMOVE = 'COMPARE_LIST_REMOVE';
export const COMPARE_LIST_CLEAR = 'COMPARE_LIST_CLEAR';
export const SHOW_COMPARE = 'SHOW_COMPARE';
export const SEND_COMPARE_EMAIL = 'SEND_COMPARE_EMAIL';
export const SEND_COMPARE_EMAIL_SUCCESS = 'SEND_COMPARE_EMAIL_SUCCESS';
export const TOGGLE_COMPARE_SCROLL = 'TOGGLE_COMPARE_SCROLL';

// COMPARE SCHEMES
export const COMPARE_SCHEMES_LIST_ADD = 'COMPARE_SCHEMES_LIST_ADD';
export const COMPARE_SCHEMES_LIST_REMOVE = 'COMPARE_SCHEMES_LIST_REMOVE';
export const SHOW_COMPARE_SCHEMES = 'SHOW_COMPARE_SCHEMES';
export const SEND_COMPARE_SCHEMES_EMAIL_SUCCESS = 'SEND_COMPARE__SCHEMESEMAIL_SUCCESS';
export const TOGGLE_COMPARE_SCHEMES_SCROLL = 'TOGGLE_COMPARE__SCHEMESSCROLL';
export const COMPARE_SCHEMES_LIST_CLEAR = 'COMPARE_SCHEMES_LIST_CLEAR';

// PORTFOLIO
export const FETCH_PORTFOLIO = 'FETCH_PORTFOLIO';
export const FETCH_PORTFOLIO_COMPLETE = 'FETCH_PORTFOLIO_COMPLETE';
export const FETCH_PORTFOLIOS = 'FETCH_PORTFOLIOS';
export const FETCH_PORTFOLIO_PROPERTIES = 'FETCH_PORTFOLIO_PROPERTIES';
export const FETCH_PORTFOLIO_PROPERTIES_CATEGORY = 'FETCH_PORTFOLIO_PROPERTIES_CATEGORY';
export const SET_PORTFOLIO_PROPERTIES = 'SET_PORTFOLIO_PROPERTIES';
export const FETCH_PORTFOLIO_SCHEMES = 'FETCH_PORTFOLIO_SCHEMES';
export const FETCH_PORTFOLIO_SCHEMES_CATEGORY = 'FETCH_PORTFOLIO_SCHEMES_CATEGORY';
export const SET_PORTFOLIO_SCHEMES = 'SET_PORTFOLIO_SCHEMES';
export const SET_PORTFOLIO = 'SET_PORTFOLIO';
export const SET_PORTFOLIOS = 'SET_PORTFOLIOS';
export const CLEAR_PORTFOLIO = 'CLEAR_PORTFOLIO';
export const SET_PORTFOLIO_STYLES = 'SET_PORTFOLIO_STYLES';
export const SET_ACTIVE_PORTFOLIO_TAB = 'SET_ACTIVE_PORTFOLIO_TAB';

// TOOLTIP
export const TOOLTIP_SHOW_TIP = 'TOOLTIP_SHOW_TIP';
export const TOOLTIP_HIDE_TIP = 'TOOLTIP_HIDE_TIP';
export const TOOLTIP_SET_LOCATION = 'TOOLTIP_SET_LOCATION';
export const TOOLTIP_DISABLE = 'TOOLTIP_DISABLE';


export const GET_TRAVEL_TIMES = 'GET_TRAVEL_TIMES';
export const SET_TRAVEL_TIMES = 'SET_TRAVEL_TIMES';
export const SEND_REP_EMAIL = 'SEND_REP_EMAIL';
export const SEND_REP_EMAIL_SUCCESS = 'SEND_REP_EMAIL_SUCCESS';