

// Provisional HTTP headers for most responses
let baseHeaders = {
    "authorization": "",
    "accept": "application/json",
    // "content-type": "application/json",
}

// Update headers with state at time of invocation
export const createHeaders = auth => {
    if (auth)
        return { ...baseHeaders, authorization: `Bearer ${auth.access_token}` }
    else if (localStorage.getItem('CR_USER_AUTH'))
        return { ...baseHeaders, authorization: `Bearer ${JSON.parse(localStorage.getItem('CR_USER_AUTH')).access_token}` }

    return baseHeaders
}


// Create envelope
export const createEnvelope = auth => {
    
    
    return {}
}