import { combineEpics } from 'redux-observable';

// import portfolioEpics from './portfolio';
import propertiesEpics from './properties';
import schemesEpics from './schemes';
import authEpics from './auth';
import repsEpics from './reps';

export const rootEpic = combineEpics(
    ...propertiesEpics,
    ...schemesEpics,
    ...authEpics,
    ...repsEpics,
);

