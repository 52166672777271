

// Min and Max screen sizes across site
export const _BREAKS = {
    huge: '1800px',
    extra: '1500px',
    large: '1200px',
    medium: '800px',
    small: '500px',
    // max: '1200px',
    // min: '700px',
}

export const _PADDING = {}


export const cleanUnits = val => val.replace(/(px|em|rem|%|vh|vw|cm|ex|in|mm|pt)/gi, '')

// Breakpoint shorthands
export const breakOver = point => `@media screen and (min-width: ${point}) `
export const breakUnder = point => `@media screen and (max-width: ${point}) `


// Set variable font size between min and max screen sizes
export const fluidCalc = (min, max, minView = _BREAKS.min, maxView = _BREAKS.max) => {
    return `calc( ${min} + (${cleanUnits(max)} - ${cleanUnits(min)}) * (100vw - ${minView}) / (${cleanUnits(maxView)} - ${cleanUnits(minView)}))`
};


// Multipliers taken from: http://type-scale.com/
// Upper multiplier = 1.250 (Major Third)
// Lower multiplier = 1.125 (Major Second)
export const _TYPE = {
    h1: { // Mult * Mult * Mult * Mult * Mult * 1em
        upper: '3.052em', // 48.832
        lower: '1.802em', // 28.832
    },
    h2: { // Mult * Mult * Mult * Mult * 1em
        upper: '2.441em', // 39.056 @ 16px
        lower: '1.602em', // 25.632
    },
    h3: { // Mult * Mult * Mult * 1em
        upper: '1.953em', // 31.248 @ 16px
        lower: '1.424em',
    },
    h4: { // Mult * Mult * 1em
        upper: '1.563em', // 25.008 @ 16px
        lower: '1.266em',
    },
    h5: { // Mult * 1em
        upper: '1.25em',
        lower: '1.125em',
    },
    h6: { // (1em / $Mult)
        upper: '0.8em',
        lower: '0.888em',
    },
}



// export const lightOrDark = (color) => {

//     // Variables for red, green, blue values
//     var r, g, b, hsp;
    
//     // Check the format of the color, HEX or RGB?
//     if (color.match(/^rgb/)) {

//         // If HEX --> store the red, green, blue values in separate variables
//         color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
//         r = color[1];
//         g = color[2];
//         b = color[3];
//     } 
//     else {
        
//         // If RGB --> Convert it to HEX: http://gist.github.com/983661
//         color = +("0x" + color.slice(1).replace( 
//         color.length < 5 && /./g, '$&$&'));

//         r = color >> 16;
//         g = color >> 8 & 255;
//         b = color & 255;
//     }
    
//     // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
//     hsp = Math.sqrt(
//     0.299 * (r * r) +
//     0.587 * (g * g) +
//     0.114 * (b * b)
//     );

//     // Using the HSP value, determine whether the color is light or dark
//     if (hsp>127.5) {

//         return 'light';
//     } 
//     else {

//         return 'dark';
//     }
// }