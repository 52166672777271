import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError, withLatestFrom, } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { FETCH_SCHEME, FETCH_SCHEME_ERROR, SEARCH_SCHEME, FETCH_SCHEMES_FOR_COMPANY, SET_SCHEMES, SET_SCHEME_VIEW_PAGE, SET_SCHEMES_PER_PAGE, FETCH_SCHEMES_OUTLINE_CHUNK, FETCH_SCHEMES_OUTLINE_COMPLETE, SET_SCHEMES_VIEW, UPDATE_SCHEMES_VIEW, APPLY_SCHEMES_FILTERS, SET_SCHEMES_VIEW_PAGE, FETCH_SCHEMES_OUTLINE_START, RESET_SCHEMES_FILTERS, SET_SCHEMES_FILTER, SET_VISIBLE_CATEGORY, } from "../_constants";
import { API_GetSchemeDetail, API_GetSchemeAvailability, API_SearchScheme, API_GetSchemeReduced, API_GetScheme } from './_API-routes';
import { createHeaders } from './_sharedFunctions';

import { setViewPages, } from "../_actions";
import { setScheme, setSchemeAvailability, searchSchemeResults, setSchemes, setVisibleSchemesByPage, setSchemeView } from '../_actions/schemes';

import { updateSchemesView, filterSchemes } from '../components/_sharedFunctions';



const searchSchemeByNameEp = (action$) => action$.pipe(
    ofType(SEARCH_SCHEME),
    switchMap(({ payload }) => {
        let query = {
            filter: [`name=@${payload}`]
        };
        return ajax.getJSON(API_SearchScheme(query))
    }
    ),
    map(response => {
        return searchSchemeResults(response)

    })
)


// Fetch all scheme information for a single scheme
const fetchSchemeEp = (action$, state$) => action$.pipe(
    ofType(FETCH_SCHEME),
    withLatestFrom(state$),
    switchMap(([{ payload, auth }, state]) => {
        let query = {
            id: payload,
            filter: [
                'availability=To+Let,availability=Under+Offer',
                'category_code=HS,category_code=RW,category_code=SC'
            ],
        };
        auth = !auth ? state.auth : auth;
        return ajax.getJSON(API_GetSchemeDetail(query, auth), createHeaders(auth))
    }),
    map(response => setScheme(response)),
    catchError(error => of({
        type: FETCH_SCHEME_ERROR,
        payload: error.xhr.response,
        error: true
    }))
)


// Fetch all scheme information for a single scheme
const fetchSchemePropertiesEp = (action$, state$) => action$.pipe(
    ofType(FETCH_SCHEME),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
        let query = {
            filter: [`scheme_id=${payload}`]
        }
        return ajax.getJSON(API_GetSchemeAvailability(query, state.auth), createHeaders(state.auth))
    }),
    map(response => setSchemeAvailability(response))
)

// Fetch all property information for a specific company and category code
const fetchSchemesForCompany = (action$, state$) => action$.pipe(
    ofType(FETCH_SCHEMES_FOR_COMPANY),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
        let query = {
            filter: [
                `company_id=${payload.company}`,
            ]
        };
        return ajax.getJSON(API_GetScheme(query, auth), createHeaders(auth))
    }),
    map(response => {
        return setSchemes(response)
    })
)


// When properties are loaded from COG, apply the view action to create a usable page on /properties
const setViewStateFromSchemesEp = action$ => action$.pipe(
    ofType(SET_SCHEMES),
    map(({ payload }) => setSchemeView(payload)),
)


// If someone clicks a page in the pagination bar, we need to change the visible properties
const setVisibleSchemesFromPageChangeEp = action$ => action$.pipe(
    ofType(SET_SCHEME_VIEW_PAGE),
    map(({ payload }) => setVisibleSchemesByPage(payload)),
)


// When the perPage param is changed, we probably want to update the visible properties
const updateVisibleSchemesPerPageEp = action$ => action$.pipe(
    ofType(SET_SCHEMES_PER_PAGE),
    map(({ payload, properties }) => setViewPages(payload, properties)),
)



const fetchSchemesOutlineChunkEp = (action$, state$) => action$.pipe(
    ofType(
        FETCH_SCHEMES_OUTLINE_START,
        FETCH_SCHEMES_OUTLINE_CHUNK
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        let query = {
            pagesize: state.schemesReducerNEW.data.perPage,
            page: state.schemesReducerNEW.data.currentPage,
            envelope: true,
            fields: [
                'id',
                'address_id',
                'name',
                'size_ft',
                'category_code',
                'sponsored',
                'thumbnail_uri'
            ],
            with: [
                'address.place',
            ],
            filter: [
                'visible=1',
                'active=1',
            ],
        }

        // Add a require reulst be sponsred if filter set
        if (state.schemesReducerNEW.filters.sponsored) query.filter.push('sponsored==1')

        // Add a company id filter if defined
        if (state.schemesReducerNEW.filters.company.length > 0) query.filter.push(state.schemesReducerNEW.filters.company.map(id => `company_id==${id}`).join())

        // Add a category code filter if defined
        if (state.schemesReducerNEW.filters.category.length > 0) query.filter.push(`category_code=${state.schemesReducerNEW.filters.category}`)

        return ajax.getJSON(API_GetSchemeReduced(query, state.auth), createHeaders(state.auth))
    }),
    map(response => {
        if (response.current_page >= response.last_page) {
            return ({
                type: FETCH_SCHEMES_OUTLINE_COMPLETE,
                payload: {
                    results: response.data,
                    pages: response.last_page,
                    totalResults: response.total,
                }
            })
        } else {
            return ({
                type: FETCH_SCHEMES_OUTLINE_CHUNK,
                payload: {
                    results: response.data,
                    pages: response.last_page,
                    totalResults: response.total,
                }
            })
        }
    })
)

const updateSchemesFilterEp = (action$, state$) => action$.pipe(
    ofType(
        FETCH_SCHEMES_OUTLINE_COMPLETE,
        SET_SCHEMES_FILTER,
        RESET_SCHEMES_FILTERS,
        SET_VISIBLE_CATEGORY,
    ),
    withLatestFrom(state$),
    map(([action, {schemes}]) => {
        return ({
            type: APPLY_SCHEMES_FILTERS,
            payload: filterSchemes(schemes.categories[schemes.categories.current], schemes.filters)
        })
    })
)

const updateSchemesViewEp = (action$, state$) => action$.pipe(
    ofType(
        SET_SCHEMES_PER_PAGE,
        SET_SCHEMES_VIEW_PAGE,
        APPLY_SCHEMES_FILTERS,
        UPDATE_SCHEMES_VIEW,
    ),
    withLatestFrom(state$),
    map(([action, { schemes }]) => {
        return ({
            type: SET_SCHEMES_VIEW,
            payload: updateSchemesView(schemes.data.results, schemes.view)
        })
    })
)



let schemesEpics = [
    fetchSchemeEp,
    fetchSchemePropertiesEp,
    searchSchemeByNameEp,
    fetchSchemesForCompany,
    setViewStateFromSchemesEp,
    setVisibleSchemesFromPageChangeEp,
    updateVisibleSchemesPerPageEp,
    fetchSchemesOutlineChunkEp,
    updateSchemesFilterEp,
    updateSchemesViewEp,
];

export default schemesEpics;
