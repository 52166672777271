import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { breakOver } from './styled/_shared';
import NavBarUser from './NavBarUser';
import { authLogout } from '../_actions/auth';
import { SmoothWrapper } from './styled/Wrappers';
import { ActionButton } from './styled/Buttons';
import { CRLogoSVG } from './svg/CRLogo'

const TempSpacer = styled.div`
  width: 100%;
  height: 150px;
`;

const FixedNavs = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  display: grid;
  grid-template-rows:
    100px;
  grid-template-areas:
    "nav";
`;

const Navbar = styled.div`
  grid-area: nav;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background-color: rgb(20, 27, 32);
`;

const NavBarWrapper = styled(SmoothWrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
const Logo = styled(Link)`
  height: 50%;
  padding: 0;
  margin: 0;
  display: block;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;

  svg {
    height: 100%;
  }

  > svg:nth-of-type(2),
  > svg:nth-of-type(3) {
    display: none;
  }

  ${breakOver('650px')} {
    > svg:nth-of-type(1) {
      display: none;
    }
    > svg:nth-of-type(2) {
      display: block;
    }
  }

  ${breakOver('1000px')} {
    > svg:nth-of-type(2) {
      display: none;
    }
    > svg:nth-of-type(3) {
      display: block;
    }
  }
`;


const Nav = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  > * {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NavUser = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

const NavUserLogin = styled(ActionButton)`
  /* display: block; */
  background-color: #EC7474;
`;

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      topOffset: 0,
      reduceSize: false,
    };
  }

  componentDidMount() {
    this.updateNavPos();
    window.addEventListener('scroll', this.updateNavPos);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateNavPos);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeDropdown() {
    this.setState({
      isOpen: false
    });
  }

  updateNavPos = () => {
    this.setState({ topOffset: window.scrollY })
  }

  handleLogOut() {
    this.props.authLogout(this.props.user)
    localStorage.removeItem('CR_USER_AUTH');
  }

  render() {
    let { auth, user, location } = this.props;

    return (
      <Fragment>
        <TempSpacer></TempSpacer>

        <FixedNavs style={{ top: this.state.topOffset < 50 ? `-${this.state.topOffset}px` : '-50px' }} reduceSize={this.state.topOffset > 70}>
          <Navbar reduceSize={this.state.reduceSize}>
            <NavBarWrapper>
              <Logo to="/" alt="Completely Retail">
                <CRLogoSVG uid="nav1" variant="icon" />
                <CRLogoSVG uid="nav2" variant="logo" />
                <CRLogoSVG uid="nav3" variant="full" />
              </Logo>

              <Nav>
                {auth.loggedIn ? (
                  <NavBarUser user={user} handleLogOut={this.handleLogOut} />
                ) : (
                    <NavUser as={Link} to={{ pathname: "/auth/login", state: { from: location.pathname } }} onClick={this.closeDropdown}>
                      <NavUserLogin>Login</NavUserLogin>
                    </NavUser>
                  )}
              </Nav>
            </NavBarWrapper>
          </Navbar >
        </FixedNavs>

      </Fragment>
    );
  }
}

const mapDispatch = (dispatch) => ({
  authLogout(auth) { dispatch(authLogout(auth)) }
})

const mapState = (state) => ({
  tooltip: state.tooltip,
  user: state.user,
  auth: state.auth,
})

export default connect(mapState, mapDispatch)(NavBar);