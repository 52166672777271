import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';

let _Button = (props) => `
    background-color: #FBFBFB;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    text-decoration: none;
    :focus {
        box-shadow: none;
        outline: none;
    }
    :hover {
        color: initial;
        cursor: pointer;
    }
    ${props.small ? `
        font-size: .9rem;
        padding: .25rem .75rem;
    ` : ''}
    ${props.success ? `
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
    ` : ''}
`;

export const Button = styled.button`
    ${(props) => _Button(props)}
`;

let _ActionButton = (props) => `
    background-color: ${props.background || 'black'};
    color: ${props.color || 'white'};
    border: ${props.border || 'none'};
    font-weight: 600;
    padding: .4rem 1.25rem;
    text-decoration: none;
    :hover {
        color: white;
        text-decoration: none;
    }
`;

export const ActionButton = styled.button`
    ${props => _Button(props)}
    ${props => _ActionButton(props)}
`;

export const ActionButtonA = styled(animated.button)`
    ${props => _Button(props)}
    ${props => _ActionButton(props)}
`;


let _ActionLink = (props) => `
    background-color: black;
    color: white;
    border: none;
    font-weight: 600;
    padding: .4rem 1.25rem;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    display: inline-block;
    :hover {
        color: white;
        text-decoration: none;
    }
`;

export const ActionLink = styled(Link)`
    ${props => _ActionLink(props)}
`;

export const ActionHref = styled.a`
    ${props => _ActionLink(props)}
`;

let _LightButton = (props) => `
    background-color: ${ props.background || '#ffffff'};
    color: ${ props.color || '#5B5A5A'};
    border: ${ props.border || '2px solid #DADADA'};
    :hover {
        background-color: #DADADA;
        border: ${ props.border || '2px solid #DADADA'};
    }
`;

export const LightButton = styled.button`
    ${props => _Button(props)}
    ${props => _LightButton(props)}
`;

export const LightButtonA = styled(animated.button)`
    ${props => _Button(props)}
    ${props => _LightButton(props)}
`;