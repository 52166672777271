import styled from 'styled-components';

export const Nav = styled.ul`
    padding: 0;
    align-items: stretch;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style: none;
`;

export const NavItem = styled.li`
    flex-grow: 1;
`;

export const NavLink = styled.a`
    height: 100%;
    width: 100%;
    border: 0;
    text-decoration: none;
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.active ? '#fff' : '#666'};
    font-weight: 600;
    background-color: #ebebeb;
    border-radius: 0;
    text-align: center;
    background: ${props => props.active ? 'linear-gradient(90deg,#e8336b,#eb5e40)' : ''};
`;