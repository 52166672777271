import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Table } from './styled/Tables';
import RepsSearchForm from './RepsSearchForm';
import ResultsTray from './ResultsTray';
import { DownloadCSV } from './DownloadCSV';


class Reps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAgent: null,
        }
    }

    toggleActiveRep = (id) => {
        if(this.state.activeAgent !== id) {
            this.setState({activeAgent: id});
        } else {
            this.setState({activeAgent: null});
        }
    }
    
    render() {
        let { reps, company } = this.props;
        // console.log(reps.view.visible);
        return (
            <div>
                <RepsSearchForm />
                <ResultsTray variant="reps" />
                <Table>
                    <thead>
                        <tr>
                            <td>User Id</td>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Properties</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reps.view.visible.map((rep) => 
                            <Fragment key={rep.id}>
                                <tr>
                                    <td>{rep.id}</td>
                                    <td>{`${rep.forename} ${rep.surname}`}</td>
                                    <td>{rep.email}</td>
                                    <td>{rep.csvData.length}</td>
                                    <td>
                                        {(rep.csvData.length > 0) &&
                                            <DownloadCSV csvData={rep.csvData} name={`${company.name} - Reps - ${rep.forename} ${rep.surname} - Properties`} />
                                        }
                                    </td>
                                </tr>
                            </Fragment>
                        )}
                    </tbody>
                </Table>
            </div>
            
        )
    }
}

const mapState = (state) => ({
    reps: state.reps,
    company: state.company,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Reps);
