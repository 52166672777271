import { CATEOGRY_NAMES } from "../_constants/defs";

// INTERNAL FUNCTIONS

// Test to see if word contains keyword
//
// @param   string  keyword     The string we are looking for
// @param   string  word        The word we are testing against
// @return  boolean
const keywordChecker = (keyword, word) => {
    if (word && typeof word === 'string' && word.toLowerCase().search(keyword) > -1)
        return true
    else
        return false
}




// export const sort

// this function returns an array containing only the items that should appear on that page
export const paginate = (items, page_number, paginateSize = 10) => {
    --page_number; // because pages logically start with 1, but technically with 0
    return items.slice(page_number * paginateSize, (page_number + 1) * paginateSize);
}

// Simple function to ensure minimum isn't higher than maximum value, used on search forms
export const checkMinMax = (min, max) => {
    // Don't perform calc if no max is set
    if (max === "")
        return true

    // Convert to number and compare
    if (Number(max) > Number(min))
        return true
    else
        return false

}

export const compareProperties = (a, b) => {
    if (a.name < b.name)
        return -1;
    if (a.name > b.name)
        return 1;
    return 0;
}

export const comparePropertiesByScheme = (a, b) => {
    if (a.scheme && b.scheme)
        if (a.scheme.name < b.scheme.name)
            return -1;
        else if (a.scheme.name > b.scheme.name)
            return 1;
        else
            return 0;
}

export const createPaginationArray = (arrayOfItems, itemsPerPage = 10) => {
    // We need to know how many pages there will be, so first we need the length
    // then we create an array and fill it with page keys which we can map over
    // to create the pagination list further on in the app (default 20 results per page)
    let numberOfPages = Math.ceil((arrayOfItems.length / itemsPerPage));

    let paginationArray = [];
    for (var i = 1; i <= numberOfPages; i++) {
        paginationArray.push(i);
    }

    return paginationArray;
}

// Google analytics log pageview
export const logPageView = () => {
    // console.log({ page: window.location.pathname });
    // ReactGA.set({ page: window.location.pathname});
    // ReactGA.pageview(window.location.pathname);
    return null;
};

export const logPageEvent = (event) => {
    // console.log(event);
    return null;
}

// Turns 323500 to '323.5k'
export const kFormatter = (num) => {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
}

export const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

// Return the highest property price from the array of properties
// Used in the slider's max range in the search function
export const getPropertyMax = (properties, key) => {
    let newArray = [];
    let property;
    for (property of properties) {
        newArray[property[key]] = property[key]
    }
    return newArray.length - 1;
}

const comparePropertyNames = (a, aNameIsNull, b, bNameIsNull) => {
    if (aNameIsNull && bNameIsNull)
        return 0 // if all null, leave
    else if (aNameIsNull)
        return 1 // if a null, b first
    else if (bNameIsNull)
        return -1 // if b null, a first
    else {
        if (cleanString(a) < cleanString(b))
            return -1
        else if (cleanString(a) > cleanString(b))
            return 1
        else
            return 0
    }
}

const cleanString = (dirty) => dirty.toString().replace(" ", "").toLowerCase()

export const getSortedProperties = properties => properties.sort((a, b) => {
    let aNameIsNull = a.name === null ? true : false;
    let aSchemeIsNull = a.scheme === null ? true : a.scheme.name === null ? true : false;
    let bNameIsNull = b.name === null ? true : false;
    let bSchemeIsNull = b.scheme === null ? true : b.scheme.name === null ? true : false;

    if (aSchemeIsNull && bSchemeIsNull) {

        return comparePropertyNames(a.name, aNameIsNull, b.name, bNameIsNull)

    } else if (aSchemeIsNull) {
        return 1
    } else if (bSchemeIsNull) {
        return -1
    } else {

        if (a.scheme.name === b.scheme.name) {

            return comparePropertyNames(a.name, aNameIsNull, b.name, bNameIsNull)

        } else
            if (cleanString(a.scheme.name) < cleanString(b.scheme.name))
                return -1
            else if (cleanString(a.scheme.name) > cleanString(b.scheme.name))
                return 1
            else
                return 0

    }

})


// Sort the active results reps into their categories
export const sortRepsByCategory = reps => {
    let sortedReps = {
        agent: [],
        asset_manager: [],
        owner: [],
        centre_manager: [],
    };
    let repsOrderedByCompany = reps.sort(function (a, b) {
        return parseFloat(a.company_id) - parseFloat(b.company_id);
    });

    let rep;
    for (rep of repsOrderedByCompany) {
        sortedReps[rep.type].push(rep);
    }

    return sortedReps;
}

// Find which pages are currently visible
//
// @param   array   results     All the results in the dataset
// @param   int     activePage  The current page
// @param   int     perPage     How many results to show per page
// @return  array
export const getVisible = (results = [], activePage, perPage) => {
    let start = (activePage - 1) * perPage;
    let end = start + perPage;
    return results.slice(start, end)
}

// export const generateCSVData = data => {
//     let csvData = []
//     data.map()
// }

export const generatePropertyCSVData = ({ properties }) => {
    let csvData = [];
    properties.map(property => {
        let propertyObject = {
            id: property.id,
            name: property.name,
            availability: property.availability,
            category: CATEOGRY_NAMES[property.category_code],
            use_class: property.use_class,
            rent_pa: property.rent_pa,
            size_ft_min: property.size_ft_min,
            size_ft_max: property.size_ft_max,
            size_m_min: property.size_m_min,
            size_m_max: property.size_m_max,
            line1: property.address.line1,
            line2: property.address.line2,
            line3: property.address.line3,
            postcode: property.address.postcode,
            scheme: property.scheme ? property.scheme.name : '',
            link: `https://react-bootstrap.stage.coghub.net/property/${property.id}`
        }
        csvData.push(propertyObject);
        return null;

    })
    return csvData;
}

export const generateSchemeCSVData = schemes => {
    let csvData = [];
    schemes.map(scheme => {
        let schemeObject = {
            id: scheme.id,
            name: scheme.name,
            availability: scheme.availability,
            category: CATEOGRY_NAMES[scheme.category_code],
            rent_pa: scheme.rent_pa,
            size_ft: scheme.size_ft,
            size_m: scheme.size_m,
            website: scheme.website,
            line1: scheme.address.line1,
            line2: scheme.address.line2,
            line3: scheme.address.line3,
            postcode: scheme.address.postcode,
            link: `https://react-bootstrap.stage.coghub.net/scheme/${scheme.id}`
        }
        csvData.push(schemeObject);
        return null;

    })
    return csvData;
}

export const generateRepCSVData = reps => {
    let csvData = [];
    reps.map(rep => {
        let repObject = {
            id: rep.id,
            title: rep.title,
            foreName: rep.forename,
            middleName: rep.middle,
            surname: rep.surname,
            email: rep.email,
            status: rep.status,
            type: rep.type,
            avatar_id: rep.avatar_id,
            registered: rep.registered,
        }
        csvData.push(repObject);
        return null;

    })
    return csvData;
}



// Find out the min and max values from a collection of schemes
//
// @param   array   sizes       All schemes
// @return  object
//          |-int   min
//          |-int   max
export const getSizeRange = (sizes = []) => {
    // Ensure the set of results is sorted by its size in ascending order and
    // then take the first and last result to get the min and max, defaulting
    // any value of null to 0.

    if (sizes.length === 0)
        return {
            min: 0,
            max: 0,
        }

    let sortedSizes = sizes.sort(function (a, b) {
        return (a.size_ft || 0) - (b.size_ft || 0);
    });
    return {
        min: sortedSizes[0].size_ft || 0,
        max: sortedSizes[sortedSizes.length - 1].size_ft || 0,
    };
}


export const getPriceRange = (prices = []) => {
    // Ensure the set of results is sorted by its size in ascending order and
    // then take the first and last result to get the min and max, defaulting
    // any value of null to 0.
    if (prices.length === 0)
        return {
            min: 0,
            max: 0,
        }

    let sortedPrices = prices.sort(function (a, b) {
        return (a.rent_pa || 0) - (b.rent_pa || 0);
    });
    return {
        min: sortedPrices[0].rent_pa || 0,
        max: sortedPrices[sortedPrices.length - 1].rent_pa || 0,
    };
}








// ==================================================
// =====           PROPERTY FUNCTIONS           =====
// ==================================================


// Return the generic properties results state usable by different components
export const preparePropertyState = properties => {
    let sortedProperties = getSortedProperties(properties);
    // let sponsored = [];
    // let unsponsored = [];
    // for (let property of sortedProperties) {
    //     if (property.scheme && property.scheme.sponsored === 1)
    //         sponsored.push(property)
    //     else
    //         unsponsored.push(property)
    // }

    // sortedProperties = [...sponsored, ...unsponsored];

    return {
        immutableProperties: sortedProperties,
        properties: sortedProperties,
        maxPropertyRent: getPropertyMax(sortedProperties, 'rent_pa'),
        maxPropertySize: getPropertyMax(sortedProperties, 'size_ft_max'),
        visibleProperties: paginate(sortedProperties, 1, 20),
        ready: true,
        loading: false,
        filters: {
            availability: "",
            category: "",
            keyword: "",
            allowNullRent: true,
            allowNullSize: true,
            minRent: 0,
            maxRent: getPropertyMax(sortedProperties, 'rent_pa'),
            minSize: 0,
            maxSize: getPropertyMax(sortedProperties, 'size_ft_max')
        }
    }
}

export const prepareViewState = properties => {

    return {
        activePage: 1,
        pages: createPaginationArray(properties, 20),
        viewMode: 'list',
        showCompare: false,
        perPage: 20
    }
}


// export const filterProperties = () => {}



// Property Search Filter module, this is a big one
export const propertySearch = ({ availability, keyword, maxRent, maxSize, allowNullRent, allowNullSize }, properties, perPage) => {
    // Start with empty array of properties on each search
    let resultsFilteredBySearch = [];
    let zeroresults = false;
    let empty = true;
    // Used to prevent duplicates from results array
    function idExists(id) {
        return resultsFilteredBySearch.some(function (el) {
            return el.id === id;
        });
    }

    // Keyword search, skip if empty
    if (keyword !== "") {
        keyword = keyword.toLowerCase();
        properties.map(property => {
            if (typeof property.name === 'string' && property.name.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }
            if (typeof property.address.line1 === 'string' && property.address.line1.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (typeof property.address.line2 === 'string' && property.address.line2.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (typeof property.address.line3 === 'string' && property.address.line3.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (typeof property.address.postcode === 'string' && property.address.postcode.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (typeof property.address.place.name === 'string' && property.address.place.name.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (property.scheme && typeof property.scheme.name === 'string' && property.scheme.name.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }

            if (property.use_class && typeof property.use_class === 'string' && property.use_class.toLowerCase().search(keyword) > -1) {
                if (!idExists(property.id)) {
                    resultsFilteredBySearch.push(property);
                }
            }
            empty = false;
            return null;
        })
        if (resultsFilteredBySearch.length === 0) {
            zeroresults = true;
        }
    }

    // // Category search
    // if (category !== "" && !zeroresults) {
    //     // If there are no filtered properties already:
    //     if (resultsFilteredBySearch.length === 0) {
    //         let array = [];
    //         switch (category) {
    //             case 'Retail':
    //                 array = ['HS', 'SC', 'RW']
    //                 break;
    //             case 'Industrial':
    //                 array = ['HB', 'IN']
    //                 break;
    //             case 'Office':
    //                 array = ['OF']
    //                 break;
    //             default:
    //                 return null
    //         }
    //         properties.map(property => {
    //             if (array.includes(property.category_code)) {
    //                 if (!idExists(property.id)) {
    //                     resultsFilteredBySearch.push(property)
    //                 }
    //             }
    //             return null;
    //         })
    //         if (resultsFilteredBySearch.length === 0) {
    //             zeroresults = true;
    //         }
    //         empty = false;
    //         // If there are filtered properties already:
    //         // Create new array and push matching objects into it, and have it replace the resultsFilteredBySearch array at the end
    //     } else {
    //         let newArray = [];
    //         resultsFilteredBySearch.map(property => {
    //             let array = [];
    //             switch (category) {
    //                 case 'Retail':
    //                     array = ['HS', 'SC', 'RW']
    //                     break;
    //                 case 'Industrial':
    //                     array = ['HB', 'IN']
    //                     break;
    //                 case 'Office':
    //                     array = ['OF']
    //                     break;
    //                 default:
    //                     return null
    //             }
    //             if (array.includes(property.category_code)) {
    //                 newArray.push(property);
    //             }
    //             return null;
    //         })

    //         resultsFilteredBySearch = newArray;
    //         empty = false;
    //     }
    // }

    // Availability filter
    if (availability !== "" && !zeroresults) {
        // console.log(resultsFilteredBySearch);
        // If there are no filtered properties already:
        if (resultsFilteredBySearch.length === 0) {
            let array = [];
            switch (availability) {
                case 'available':
                    array = ['To Let']
                    break;
                case 'under-offer':
                    array = ['Under Offer']
                    break;
                case 'let':
                    array = ['Let']
                    break;
                case 'all':
                    array = ['To Let', 'Under Offer', 'Let']
                    break;
                default:
                    return null
            }
            properties.map(property => {
                if (array.includes(property.availability)) {
                    if (!idExists(property.id)) {
                        resultsFilteredBySearch.push(property)
                    }
                }
                return null;
            })

            if (resultsFilteredBySearch.length === 0) {
                zeroresults = true;
            }
            empty = false;
            // If there are filtered properties already:
            // Create new array and push matching objects into it, and have it replace the resultsFilteredBySearch array at the end
        } else {
            let newArray = [];
            resultsFilteredBySearch.map(property => {
                let array = [];
                switch (availability) {
                    case 'available':
                        array = ['To Let']
                        break;
                    case 'under-offer':
                        array = ['Under Offer']
                        break;
                    case 'let':
                        array = ['Let']
                        break;
                    case 'all':
                        array = ['To Let', 'Under Offer']
                        break;
                    default:
                        return null
                }
                if (array.includes(property.availability)) {
                    newArray.push(property);
                }
                return null;
            })

            resultsFilteredBySearch = newArray;
            empty = false;
        }
    }

    if (maxRent && !zeroresults) {
        // If there are no filtered properties already:
        if (resultsFilteredBySearch.length === 0) {
            properties.map(property => {
                if (allowNullRent) {
                    if (!property.rent_pa || property.rent_pa <= maxRent)
                        resultsFilteredBySearch.push(property)
                } else {
                    if (property.rent_pa && property.rent_pa <= maxRent) {
                        resultsFilteredBySearch.push(property)
                    }
                }
                return null;
            })

            if (resultsFilteredBySearch.length === 0) {
                zeroresults = true;
            }
            empty = false;
            // If there are filtered properties already:
            // Create new array and push matching objects into it, and have it replace the resultsFilteredBySearch array at the end
        } else {
            let newArray = [];
            resultsFilteredBySearch.map(property => {
                if (allowNullRent) {
                    if (!property.rent_pa || property.rent_pa <= maxRent)
                        newArray.push(property)
                } else {
                    if (property.rent_pa && property.rent_pa <= maxRent) {
                        newArray.push(property)
                    }
                }
                return null;
            })
            resultsFilteredBySearch = newArray;
            empty = false;
        }
    }

    if (maxSize && !zeroresults) {
        // If there are no filtered properties already:
        if (resultsFilteredBySearch.length === 0) {
            properties.map(property => {
                if (allowNullSize) {
                    if (!property.size_ft_max || property.size_ft_max <= maxSize)
                        resultsFilteredBySearch.push(property)
                } else {
                    if (property.size_ft_max && property.size_ft_max <= maxSize) {
                        resultsFilteredBySearch.push(property)
                    }
                }
                return null;
            })

            if (resultsFilteredBySearch.length === 0) {
                zeroresults = true;
            }
            empty = false;
            // If there are filtered properties already:
            // Create new array and push matching objects into it, and have it replace the resultsFilteredBySearch array at the end
        } else {
            let newArray = [];
            resultsFilteredBySearch.map(property => {
                // If we're allowing null values. acceptance is 100%
                if (allowNullSize) {
                    if (!property.size_ft_max || property.size_ft_max <= maxSize)
                        newArray.push(property)
                } else {
                    if (property.size_ft_max && property.size_ft_max <= maxSize) {
                        newArray.push(property)
                    }
                }
                return null;
            })
            resultsFilteredBySearch = newArray;
            empty = false;
        }
    }

    resultsFilteredBySearch.sort(compareProperties);
    resultsFilteredBySearch.sort(comparePropertiesByScheme);
    if (empty) {
        return {
            properties: properties,
            visibleProperties: paginate(properties, 1, perPage),
            pages: createPaginationArray(properties, perPage),
        }
    } else {
        // Send payload to filterResults() function in the parent component
        return {
            properties: resultsFilteredBySearch,
            visibleProperties: paginate(resultsFilteredBySearch, 1, perPage),
            pages: createPaginationArray(resultsFilteredBySearch, perPage),
        }
    }
}











// ==================================================
// =====           SCHEMES FUNCTIONS            =====
// ==================================================


// Get only the results that comply with the set filters
//
// @param   array       schemes     All results
// @param   object      filters     All filters from the reducer
// @return  array
export const filterSchemes = (schemes, { keyword, allowNullSize, size }) => {
    // Begin with the full set of results and remove invalid results according
    // to the filters. Ideally this will be done in order of least to most intensive
    // operation so that the heaviest opertaions take place on a reduced array.
    let filteredSchemes = schemes;

    if (!allowNullSize)
        filteredSchemes = filteredSchemes.filter(scheme => scheme.size_ft !== null)

    if (size && size.max)
        filteredSchemes = filteredSchemes.filter(scheme => scheme.size_ft >= size.min && scheme.size_ft <= size.max)

    if (keyword && keyword !== '') {
        keyword = keyword.toLowerCase();
        filteredSchemes = filteredSchemes.filter(scheme => {
            if (keywordChecker(keyword, scheme.name))
                return true;
            else
                if (scheme.address)
                    if (keywordChecker(keyword, scheme.address.line1) || keywordChecker(keyword, scheme.address.line2) || keywordChecker(keyword, scheme.address.line3) || keywordChecker(keyword, scheme.address.postcode) || keywordChecker(keyword, scheme.address.place.name))
                        return true;
                    else
                        return false;
            return null;
        })
    }

    return filteredSchemes;
}

// Entrypoint for other functions to create a usable object for the
// schemes reducer's view
//
// @param   array       filteredSchemes     All the schemes in the results
// @param   object      view                The current view from the reducer
// @return  object
//          |-array     visible
//          |-int       pages
//          |-int       totalResults
export const updateSchemesView = (schemes, view) => {
    return ({
        visible: getVisible(schemes, view.activePage, view.perPage),
        pages: Math.ceil((schemes.length / view.perPage)),
        totalResults: schemes.length,
    })
}











// ==================================================
// =====             REPS FUNCTIONS             =====
// ==================================================


// Get only the results that comply with the set filters
//
// @param   array       schemes     All results
// @param   object      filters     All filters from the reducer
// @return  array
export const filterReps = (reps, { keyword }) => {
    // Begin with the full set of results and remove invalid results according
    // to the filters. Ideally this will be done in order of least to most intensive
    // operation so that the heaviest opertaions take place on a reduced array.
    let filteredReps = reps;

    // if (!allowNullSize)
    //     filteredReps = filteredReps.filter(rep => rep.size_ft !== null)

    // if (size && size.max)
    //     filteredReps = filteredReps.filter(rep => rep.size_ft >= size.min && rep.size_ft <= size.max)

    if (keyword && keyword !== '') {
        keyword = keyword.toLowerCase();
        filteredReps = filteredReps.filter(rep => {
            if (keywordChecker(keyword, rep.forename) || keywordChecker(keyword, rep.middle) || keywordChecker(keyword, rep.surname) || keywordChecker(keyword, rep.email))
                return true;
            else
                return false;
        })
    }


    // Finally sort and return the filtered results
    return filteredReps.sort((a,b) => {
        let alpha = `${a.forename}_${a.middle}_${a.surname}`.toLowerCase();
        let bravo = `${b.forename}_${b.middle}_${b.surname}`.toLowerCase();
        
        if (alpha < bravo)
            return -1
        else if (alpha > bravo)
            return 1
        else
            return 0
    })
}

// Entrypoint for other functions to create a usable object for the
// schemes reducer's view
//
// @param   array       filteredSchemes     All the schemes in the results
// @param   object      view                The current view from the reducer
// @return  object
//          |-array     visible
//          |-int       pages
//          |-int       totalResults
export const updateRepsView = (reps, view) => {
    return ({
        visible: getVisible(reps, view.activePage, view.perPage),
        pages: Math.ceil((reps.length / view.perPage)),
        totalResults: reps.length,
    })
}











// ==================================================
// =====          PORTFOLIO FUNCTIONS           =====
// ==================================================


export const getCategoryCodes = array => {
    let categories = {};
    let keys = [];
    array.forEach(value => {
        keys = Object.keys(categories);
        if (keys.indexOf(value.category_code) === -1) {
            categories[value.category_code] = {
                count: 1
            };
        } else {
            categories[value.category_code].count++;
        }
        // categories[value]
        // if(categories.indexOf(value.category_code) === -1) categories.push(value.category_code)
    });
    return categories;
}

export const getPortfolioPropertyCategoryCodes = (properties = []) => {

    let response = {
        propertyCategories: getCategoryCodes(properties)
    }

    if (properties) response.activeTab = 'properties';

    return response
}

export const getPortfolioSchemesCategoryCodes = (schemes = []) => {

    let response = {
        schemeCategories: getCategoryCodes(schemes)
    }

    if (schemes) response.activeTab = 'schemes';

    return response
}

export const formatProtfolioDescription = description => {

    return JSON.parse(description, (key, value) => {
        if (key === 'description') {
            return value.split('\n')
            // return value.replace(/(\r)/g, '<br>').split('\n')
        } else
            return value
    }).description
}

export const setPortfolioStyling = styles => {

    let defaultStyles = {
        main: false,
        dark: false,
        iconStyle: {
            backgroundColor: false,
            backgroundImage: false
        },
        textStyle: {
            color: false
        }
    }

    return { ...defaultStyles, ...JSON.parse(styles) }
}

export const preparePortfolioState = activePortfolio => {

    let cleanPortfolio = {
        ...activePortfolio,
        ...getPortfolioPropertyCategoryCodes(activePortfolio.property),
        ...getPortfolioSchemesCategoryCodes(activePortfolio.scheme),
        styles: setPortfolioStyling(activePortfolio.data),
        description: formatProtfolioDescription(activePortfolio.data) || [],
    };


    return cleanPortfolio
}