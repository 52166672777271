import { AUTH_LOGIN, AUTH_REMEMBER_TOGGLE, AUTH_LOGOUT, AUTH_SET_TOKENS, AUTH_SET_USER } from "../_constants";


export const authLogin = (data) => ({
    type: AUTH_LOGIN,
    payload: data
})

export const authRememberToggle = (data) => ({
    type: AUTH_REMEMBER_TOGGLE,
    payload: data
})

export const authLogout = (data) => ({
    type: AUTH_LOGOUT,
    payload: data
})


export const authSetTokens = (data) => ({
    type: AUTH_SET_TOKENS,
    payload: data
})

export const authSetUser = (data) => ({
    type: AUTH_SET_USER,
    payload: data
})