import { ajax } from 'rxjs/ajax';
import { map, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { SET_PROPERTIES, FILTER_AVAILABILITY, FILTER_NULL, FILTER_KEYWORD, SET_PROPERTIES_PER_PAGE, FETCH_PROPERTY_INFO, GET_TRAVEL_TIMES, SET_PROPERTY_VIEW_PAGE, FETCH_PROPERTIES_FOR_COMPANY, FILTER_UPDATE, FETCH_COMPANY } from "../_constants";
import { API_GetProperty, API_GetPropertyDetail, API_GetTravelTimes, API_getCompany } from './_API-routes';
import { createHeaders } from './_sharedFunctions';

import { setViewPages, filterProperties, setPropertyView, setCompany } from "../_actions";
import { setProperties, setVisiblePropertiesByPage, setProperty, setTravelTimes } from "../_actions/properties";

// Fetch company info
const fetchCompanyEp = (action$, state$) => action$.pipe(
    ofType(FETCH_COMPANY),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
        let query = {
            filter: [
                `company_id=${payload}`
            ]
        };
        return ajax.getJSON(API_getCompany(query), createHeaders(auth))
    }),
    map(response => {
        return setCompany(response[0])
    }
    ))

// Fetch all property information for a single property
const fetchPropertyEp = (action$, state$) => action$.pipe(
    ofType(FETCH_PROPERTY_INFO),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
        let query = {
            id: payload
        };
        return ajax.getJSON(API_GetPropertyDetail(query, auth), createHeaders(auth))
    }),
    map(response => setProperty(response))
)

// Fetch all property information for a specific company and category code
const fetchPropertiesForCompany = (action$, state$) => action$.pipe(
    ofType(FETCH_PROPERTIES_FOR_COMPANY),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
        let query = {
            filter: [
                `company_id=${payload.company}`
            ]
        };
        return ajax.getJSON(API_GetProperty(query, auth), createHeaders(auth))
    }),
    map(response => setProperties(response)),
)

const processPropertiesEp = action$ => action$.pipe(
    ofType(
        FILTER_UPDATE,
        FILTER_KEYWORD,
        FILTER_AVAILABILITY,
        // FILTER_MAX_SIZE,
        // FILTER_MAX_RENT,
        FILTER_NULL,
    ),
    map(() => filterProperties())
)


// When properties are loaded from COG, apply the view action to create a usable page on /properties
const setViewStateFromPropertiesEp = action$ => action$.ofType(SET_PROPERTIES).pipe(
    map(({ payload }) => setPropertyView(payload))
)


// If someone clicks a page in the pagination bar, we need to change the visible properties
const setVisiblePropertiesFromPageChangeEp = action$ => action$.ofType(SET_PROPERTY_VIEW_PAGE).pipe(
    map(({ payload }) => setVisiblePropertiesByPage(payload))
)


// When the perPage param is changed, we probably want to update the visible properties
const updateVisiblePropertiesPerPageEp = action$ => action$.ofType(SET_PROPERTIES_PER_PAGE).pipe(
    map(({ payload, properties }) => setViewPages(payload, properties))
)


const getTravelTimesByModeEp = (action$, state$) => action$.pipe(
    ofType(GET_TRAVEL_TIMES),
    withLatestFrom(state$),
    mergeMap(([{ payload, auth, mode }, state]) => {
        auth = !auth ? state.auth : auth;
        // let query = {};
        return ajax.getJSON(API_GetTravelTimes(payload, mode), createHeaders(auth))
            .pipe(
                map(response => setTravelTimes(response, mode))
            )
    })
)


let propertiesEpics = [
    fetchPropertyEp,
    fetchCompanyEp,
    getTravelTimesByModeEp,
    processPropertiesEp,
    setViewStateFromPropertiesEp,
    setVisiblePropertiesFromPageChangeEp,
    updateVisiblePropertiesPerPageEp,
    fetchPropertiesForCompany,
];

export default propertiesEpics;
