import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Table } from './styled/Tables';
import { CATEOGRY_NAMES } from '../_constants/defs';
import { ActionHref } from './styled/Buttons'
import ResultsTray from './ResultsTray'
import PropertySearchForm from './PropertySearchForm'
import { CRLogoLoader } from './svg/CRLogo';

const Loader = styled.div`
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 50px;
`;

const Properties = props => {
    let { properties } = props;

    if (properties.loading)
        return (
            <Loader>
                <CRLogoLoader />
            </Loader>
        )

    return (
        <div>
            <PropertySearchForm />
            <ResultsTray variant="properties" />

            <Table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Town</td>
                        <td>Scheme</td>
                        <td>Category</td>
                        <td>Size (ft)</td>
                        <td>Rent (pa)</td>
                        <td>Link</td>
                    </tr>
                </thead>
                <tbody>
                    {properties.visibleProperties.map(property => (
                        <tr key={property.id}>
                            <td>{property.name ? property.name : '--'}, {property.address.line1 ? property.address.line1 : ''}</td>
                            <td>{property.address.place.name ? property.address.place.name : '--'}</td>
                            <td>{property.scheme_property ? property.scheme_property.scheme.name : '--'}</td>
                            <td>{CATEOGRY_NAMES[property.category_code]}</td>
                            <td>{property.size_ft_max ? property.size_ft_max.toLocaleString() : '--'}</td>
                            <td>£{property.rent_pa ? property.rent_pa.toLocaleString() : '--'}</td>
                            <td><ActionHref href={`https://react-bootstrap.stage.coghub.net/property/${property.id}`} target="_blank" rel="noopener noreferrer">View</ActionHref></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

const mapState = (state) => {
    return {
        properties: state.propertiesReducer
    }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Properties);
