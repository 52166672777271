import React, { Component } from 'react';
import { FluidWrapper, Row } from '../components/styled/Wrappers';
import { AlertDanger } from '../components/styled/Alert';
import { ActionButton } from '../components/styled/Buttons';
import { Form, FormContainer, Input, FormGroup, Label } from '../components/styled/Forms';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { authLogin, authRememberToggle } from '../_actions/auth';

export const LoginPage = styled(Row)`
    min-height: calc(100vh - 70px);
    /* background-image: linear-gradient(to right, #d11850 0%, #e03917 100%); */
    background: url(/images/cr-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        font-weight: bold;
        font-size: 42px;
    }
    p {
        font-weight: 600;
        font-size: 20px;
    }
`;

const LinkTray = styled.div`
    max-width: 600px;
    margin: 2em auto;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    a {
        color: white;
        font-size: 20px;
    }
`;

class Login extends Component {
    state = {
        email: '',
        password: '',
        checked: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.authLogin({
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: this.state.email,
            password: this.state.password,
            grant_type: "password",
            scope: "completely"
        });
    }

    handleRememberToggle = (e) => {
        this.setState({ checked: !this.state.checked });
        this.props.authRememberToggle({
            rememberMe: !this.state.checked
        });
    }

    render() {
        if (this.props.auth.loggedIn && this.props.location.state) {
            return <Redirect to={this.props.location.state.from} />
        } else if (this.props.auth.loggedIn && !this.props.location.state) {
            return <Redirect to="/" />
        }

        return (
            <FluidWrapper>
                <LoginPage>
                    <h1>Sign In</h1>
                    <p>Enter your email address and password in the form below.</p>
                    <Form onSubmit={(e) => this.handleSubmit(e)}>
                        <FormContainer>
                            {this.props.auth.error &&
                                <AlertDanger>
                                    {this.props.auth.message}
                                </AlertDanger>
                            }
                            <FormGroup>
                                <Label htmlFor="email">Username</Label>
                                <div className="input-wrapper">
                                    <div className="icon">
                                        <img src="/images/LogIn-Username-Fill.svg" alt="Username" aria-hidden="true" />
                                    </div>
                                    <Input type="email" name="email" id="email" placeholder="Enter your email address..." onChange={(e) => this.setState({ email: e.target.value })} />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="password">Password</Label>
                                <div className="input-wrapper">
                                    <div className="icon">
                                        <img src="/images/LogIn-Password-Fill.svg" alt="Password" aria-hidden="true" />
                                    </div>
                                    <Input type="password" name="password" id="password" placeholder="Enter your password..." onChange={(e) => this.setState({ password: e.target.value })} />
                                </div>
                            </FormGroup>
                        </FormContainer>
                        <LinkTray>
                            <label className="checkbox-container">Remember me
                                <input type="checkbox" checked={this.state.checked} onChange={(e) => this.handleRememberToggle(e)} />
                                <span className="checkmark"></span>
                            </label>
                            {/* <Link to="/auth/reset">Forgot Password?</Link> */}
                        </LinkTray>
                        {/* <div className="tray">
                                <p>Not a Member? <Link to="#">Create an Account</Link></p>
                            </div> */}
                        <ActionButton type="submit">Login</ActionButton>
                    </Form>
                </LoginPage>
            </FluidWrapper>
        )
    }
}

const mapState = (state) => ({
    auth: state.auth
})

const mapDispatch = (dispatch) => ({
    authLogin(data) { dispatch(authLogin(data)) },
    authRememberToggle(data) { dispatch(authRememberToggle(data)) }
})

export default connect(mapState, mapDispatch)(Login);