import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useSpring, animated } from 'react-spring';
// import InputRange from 'react-input-range';
import ToggleButton from 'react-toggle-button';
import { Row } from './Wrappers';
import { Select, SelectWrapper } from './Select';
import { Table } from './Tables';
import 'react-input-range/lib/css/index.css'
import { Badge } from './Badge';
import { LightButton } from './Buttons';
import {breakOver} from './_shared'


export const Compare = styled(animated.div)`
    display: grid;
    grid-auto-columns: 100%;
    grid-gap: 1rem;
    padding: 1rem;
    width: 100%;
    z-index: 100;
`;

export const SpringyCompare = props => {
    const { filterHeight } = useSpring({ filterHeight: props.height });

    return (
        <animated.div style={{ height: filterHeight, overflow: 'hidden' }}>
            {props.children}
        </animated.div>
    )
}



export const ResultsContainer = styled.div`
    display: grid;
    align-items: stretch;
    /* grid-template-areas: "results map"; */
    /* grid-template-columns: 1fr 1fr; */

    ${props => props.mapVisible ? `
        grid-template-areas: "results map";
        grid-template-columns: 3fr 2fr;
        ${breakOver('1200px')}{
            grid-template-columns: 3fr minmax(600px, 2fr);
        }
    `:`
        grid-template-areas: "results";
        grid-template-columns: 1fr;
    `}
`;

export const ResultsInner = styled.div`
    grid-area: results;
    position: relative;
    display: grid;
    grid-template-rows:
        min-content
        min-content
        auto
        min-content;
    grid-row-gap: 1rem;
    flex-grow: ${props => props.double ? 2 : 1};
    max-width: 100%;
    flex-basis: 0;
    height: 100%;
    overflow-y: scroll;
    transition: transform .2s ease, -webkit-transform .2s ease;
    overflow: overlay;
    box-sizing: border-box;
    @supports (overflow: overlay) {
        overflow: overlay;
    }

    > div {
        padding-left: 15px;
        padding-right: 20px;
    }
`;


export const MapContainer = styled.div`
    grid-area: map;
    will-change: flex-grow;
    transition: flex-grow .4s ease;
    padding: 0px!important;
    height: 100%!important;
    flex-grow: ${props => props.visible ? '1' : '0'};
    height: calc(100vh - 249px);
    min-height: 600px;
`;

// export const MapContainer = styled.div`
//     will-change: flex-grow;
//     transition: flex-grow .4s ease;
//     padding: 0px!important;
//     height: ${props => props.visible ? '100%!important' : '0'};
//     flex-grow: ${props => props.visible ? '1' : '0'};
// `;

export const TopTray = styled.div`
    display: grid;
    grid-auto-columns: max-content max-content;
    grid-template-columns: max-content max-content;
    /* grid-auto-flow: column; */
    grid-template-areas: "lefttray righttray";
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    font-size: 1rem;
    span {
        font-size: 80%;
        font-weight: 400;
        margin-right: 0;
    }
`;

const Tray = styled.div`
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    align-items: center;
`;

export const LeftTray = styled(Tray)`
    grid-area: lefttray;
    justify-content: start;
`;

export const RightTray = styled(Tray)`
    grid-area: righttray;
    justify-content: end;
`;

export const PerPageSelectWrapper = styled(SelectWrapper)`
    margin: 0 5px;
`;




export const Filters = styled(animated.div)`
    position: relative;
    /* overflow: hidden; */
    background-color: #FBFBFB;
`;


export const SpringyFilters = props => {
    const { filterHeight } = useSpring({ filterHeight: props.height });

    return (
        <animated.div style={{ height: filterHeight, overflow: 'hidden' }}>
            {props.children}
        </animated.div>
    )
}


export const Filter = styled(animated.div)`
    display: block;
    position: absolute;
    top: 0;
    right: ${props => props.theme.active ? 0 : '200%'};
    display: grid;
    grid-auto-columns: 100%;
    grid-gap: 1rem;
    width: 100%;
    margin-left: 0;
    z-index: 100;
    padding: 2em;



    + div {
        @media screen and (max-width: 600px) {
            &:after {
                content: 'Swipe down to close';
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: 12px;
                color: #aaa;
            }
        }
    }
    em {
        font-size: .9rem;
        margin-bottom: .5rem;
        display: block;
    }
    p.helper {
        font-size: .8rem;
        color: #555;
        margin-top: 1rem;
    }
`;

export const FadingFilter = props => {

    const { opacity } = useSpring({
        opacity: props.active ? 1 : 0,
        config: { mass: 5, tension: 500, friction: 80 }
    });

    return (
        <ThemeProvider theme={{ active: props.active, tab: props.id }}>
            <Filter id={props.id} style={{ opacity: opacity }}>
                {props.children}
            </Filter>
        </ThemeProvider>
    )
}




export const SpringPane = props => {
    const { opacity } = useSpring({
        opacity: props.active ? 1 : 0,
        config: { mass: 5, tension: 500, friction: 80 }
    });

    return (
        <ThemeProvider theme={{ active: props.active, tab: props.id }}>
            <TabPane id={props.id} style={{ opacity: opacity }}>
                {props.children}
            </TabPane>
        </ThemeProvider>
    )
}

export const TabPane = styled(animated.div)`
    display: ${props => props.theme.active ? 'block' : 'none'};
    position: absolute;
    bottom: ${props => props.theme.active ? 0 : '200%'};
    left: 0;
    display: grid;
    grid-auto-columns: 100%;
    grid-gap: 1rem;
    width: 100%;
    margin-left: 0;
    z-index: 100;
    padding: 2em;



    + div {
        @media screen and (max-width: 600px) {
            &:after {
                content: 'Swipe down to close';
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                text-align: center;
                font-size: 12px;
                color: #aaa;
            }
        }
    }
    em {
        font-size: .9rem;
        margin-bottom: .5rem;
        display: block;
    }
    p.helper {
        font-size: .8rem;
        color: #555;
        margin-top: 1rem;
    }
`;

export const FilterGroup = styled.div`
    display: grid;
    grid-template-columns: minmax(max-content, 130px) auto;
    grid-template-areas: "label input";
    grid-auto-flow: column;
    grid-gap: 1rem;
    align-items: center;
`;

export const FilterLabel = styled.p`
    grid-area: label;
    margin: 0;
`;

export const FilterInput = styled.input`
    grid-area: input;
    width: 100%;
    max-width: 500px;
    border: 0;
    font-size: .9rem;
    padding: .4rem;
    border: 1px solid #DADADA;
    border-radius: 10px;
    background: url('/images/search.svg')  calc(100% - 6px) / 20px no-repeat;
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 20px;
        width: 20px;
    }
    :focus, :active {
        outline: none;
        box-shadow: 0 0 3pt 1pt #EC7474;
    }
`;

export const FilterToggle = styled(ToggleButton)``;

export const FilterRange = styled.div`
    width: auto;
    max-width: 500px;
    padding: 0 1rem;
    .input-range__track {
        height: .5rem;
        border: 1px solid #DADADA;
    }
    .input-range__track--active {
        background-image: linear-gradient(90deg, rgba(232, 51, 107, 1) 0%, rgba(235, 94, 64, 1) 100%);
        height: .5rem;
        border: none;
    }

    .input-range__slider {
        background-color: rgba(235, 94, 64, 1);
        border: 0;
    }

    .input-range__label {
        color: #5B5A5A;
        font-size: 11px;
    }

    .input-range__label-container {
        font-family: "proxima-nova", sans-serif;
        font-size: .7rem;
        left: 0%;
    }

    .input-range__label.input-range__label--value {
        bottom: -1.6rem;
        top: auto;
        .input-range__label-container {
            left: -50%!important;
        }
    }

    .input-range__label.input-range__label--max {
        display: none;
    }
    ${props => props.hideMinLabel ? `
    .input-range__label.input-range__label--min {
        display: none;
    }
    `: ``}

    ${props => props.doubleLabel?`
        .input-range__slider-container:nth-of-type(1) .input-range__label {
            bottom: -1.6rem
        }
        .input-range__slider-container:nth-of-type(2) .input-range__label {
            bottom: 1.1rem;
        }
    `:`
        .input-range__slider-container:nth-of-type(1) .input-range__label {
            bottom: 1.1rem;
        }
    `}

    ${props => props.hideMinButton?`
    .input-range__slider-container:nth-of-type(1) .input-range__slider {
        display: none;
    }
    `:``}

    .input-range__track.input-range__track--background .input-range__label--value .input-range__label-container {
        left: -100%;
    }
`;

export const FilterSelect = styled(Select)`
    width: 120px;
    background: url(/images/dropdown.svg) 96%/9% no-repeat;
`;

export const FilterSelectWrapper = styled(SelectWrapper)`
    ::after {
        left: 100px;
        bottom: 0;
    }
`;

export const ContentRow = styled(Row)`
    justify-content: space-between;
`;


export const SearchForm = styled.div``;

export const FilterControls = styled.ul`
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-auto-rows: 65px;

    padding: 0;
    margin: 0;
    list-style: none;
`;

export const FilterControl = styled.li`
    display: grid;
    align-items: center;

    &:last-of-type {
        margin-left: 0.5rem;
    }
`;

export const Link = styled.a`
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    align-items: center;
    height: 100%;
    border: 0;
    text-decoration: none;
    padding: 0.25rem 1rem;
    border-right: 1px solid #EDEDED;
    font-weight: 600;
    border-radius: 0;
    text-align: center;
    font-size: 1rem;
    color: ${props => props.active ? '#FFF' : '#444'};
    background: ${props => props.active ? 'linear-gradient(90deg,#e8336b,#eb5e40)' : ''};
    background-color: #FBFBFB;
    cursor: pointer;

    :hover {
        background : linear-gradient(90deg, rgba(232, 51, 107, 1) 0%, rgba(235, 94, 64, 1) 100%);
        color: white!important;
    }
    img {
        height: 1vw;
    }

    span {
        background-color: transparent;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    img {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            height: 20px;
        }
    }
`;

export const CompareBadge = styled(Badge)`
    background: ${props => props.active ? '#FFFFFF' : '#e8336b'};
    color: ${props => props.active ? '#e8336b' : '#FFFFFF'};
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;





export const Toolbar = styled(Row)`
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: space-between;
    align-items: center;
    background-color: #FBFBFB;
    position: relative;
    margin: 0;
`;


export const Lightbutton = styled(LightButton)`
    position: relative;
    padding: 0;
    width: 40px;
    color: ${props => props.active ? '#fff' : '#5b5a5a;'};
    background-color: ${props => props.active ? '#545b62' : 'white'};
    border-color: ${props => props.active ? '#4e555b' : '#dadada'};

    &:before {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        top: 20%;
        left: 20%;
        display: block;
        width: 60%;
        height: 60%;
    }
`;

export const Toggles = styled.div`
    display: grid;
    grid-template-columns: min-content min-content minmax(max-content, min-content) min-content;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 0.5rem;
    padding: 0 0.5rem;
    color: #5B5A5A;
    @media screen and (max-width: 768px) {
        position: absolute!important;
        top: 110%;
        right: 0;
        z-index: 100;
    }
    .btn, span {
        margin: 0 .5em;
    }
`;




export const ComparisonBody = styled.div`
    will-change: height;
    transition: height .4s ease;
    background-color: #FFF;
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 3em;
    }
`;

export const ComparisonText = styled.p`
    margin: 1em auto;
    width: 600px;
`;

export const ComparisonTable = styled(Table)``;

export const ComparisonTableHeader = styled.thead``;

export const ComparisonTableBody = styled.tbody``;

export const ComparisonTableRow = styled.tr``;

export const ComparisonTableText = styled.td`
    p {
        text-align: center;
    }
`;

export const ComparisonTableButtons = styled.td`
    display: grid;
    grid-auto-rows: max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-gap: .5rem;
    align-items: center;
    justify-content: center;
    padding: .25rem 0;
`;

export const ButtonTray = styled.div`
    display: flex;
    justify-content: space-between;
    div button {
        margin-right: 10px;
    }
`;

export const CompareButtonTray = styled(ButtonTray)`
    /* padding: 0 1em; */
`;