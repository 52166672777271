import { SET_PROPERTIES, SET_PROPERTIES_PER_PAGE, SET_VISIBLE_PROPERTIES, FETCH_PROPERTY_INFO, SET_PROPERTY, GET_TRAVEL_TIMES, SET_TRAVEL_TIMES, CLEAR_PROPERTY, SET_ACTIVE_PROPERTY_TAB, FETCH_PROPERTIES_FOR_COMPANY, FETCH_PROPERTIES_RECENT, FLUSH_PROPERTIES, } from '../_constants';

// properties actions
export const setProperties = (properties) => ({
    type: SET_PROPERTIES,
    payload: properties
})

export const setPropertiesPerPage = (payload, properties) => ({
    type: SET_PROPERTIES_PER_PAGE,
    payload,
    properties
})

export const setVisiblePropertiesByPage = (page, properties) => ({
    type: SET_VISIBLE_PROPERTIES,
    payload: page,
    properties
})

export const fetchProperty = (id) => ({
    type: FETCH_PROPERTY_INFO,
    payload: id
})


export const getTravelTimes = (location, auth, mode) => ({
    type: GET_TRAVEL_TIMES,
    payload: location,
    auth,
    mode
})
export const fetchPropertiesRecent = () => ({
    type: FETCH_PROPERTIES_RECENT
})

export const fetchPropertiesForCompany = (company) => ({
    type: FETCH_PROPERTIES_FOR_COMPANY,
    payload: {
        company: company
    },
})


// activeProperty actions
export const setProperty = (property) => ({
    type: SET_PROPERTY,
    payload: property
})

export const setTravelTimes = (payload, mode) => ({
    type: SET_TRAVEL_TIMES,
    payload,
    mode
})

export const clearProperty = () => ({
    type: CLEAR_PROPERTY
})

export const setActivePropertyTab = (tool, tab) => ({
    type: SET_ACTIVE_PROPERTY_TAB,
    payload: {
        key: tool,
        val: tab
    }
})

export const flushPropertiesData = () => ({
    type: FLUSH_PROPERTIES
})
