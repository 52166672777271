import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { useSpring } from 'react-spring';
import InputRange from 'react-input-range';
import { DownloadCSV } from "./DownloadCSV";
import {
    setViewTab,
    setFilterToShow,
    setDefaultFilters,
    setViewMode,
    setPropertyViewPage,
} from '../_actions';
import {
    setSchemeViewPage,
    setSchemesPerPage,
    setSchemesFilter,
    resetSchemeFilters,
} from '../_actions/schemes';
import { LightButton } from './styled/Buttons';
import {
    FadingFilter,
    Link,
    FilterControl,
    FilterLabel,
    Toolbar,
    FilterControls,
    Filters,
    FilterGroup,
    FilterInput,
    FilterToggle,
    FilterRange,
} from './styled/SearchResult';


const SpringyFilters = props => {
    const { filterHeight } = useSpring({ filterHeight: props.height });

    return (
        <Filters style={{ height: filterHeight }}>
            {props.children}
        </Filters>
    )
}



class SchemeSearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            sizeRangeMin: 0,
            sizeRangeMax: 1,
            sizeRangeValueMin: null,
            sizeRangeValueMax: null,
        };
        // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidUpdate() {
        // Update the filter & compare areas if the content has changed
        let { filterToShow, filterToShowHeight } = this.props.view;

        if (filterToShow && filterToShowHeight !== document.getElementById(filterToShow).clientHeight)
            this.props.setFilterToShow(filterToShow, document.getElementById(filterToShow).clientHeight)

        // Update the input range values
        let newMin = Math.floor(this.props.schemes.view.sizeRange.min / 1000);
        if (this.state.sizeRangeMin !== newMin)
            this.setState({ sizeRangeMin: newMin })

        let newMax = Math.ceil(this.props.schemes.view.sizeRange.max / 1000) || 1;
        if (this.state.sizeRangeMax !== newMax)
            this.setState({ sizeRangeMax: newMax })
    }

    // Set state for input on change
    handleInputChange = (value, category) => {
        // Remove foreign characters which could cause a crash
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        switch (category) {
            case 'keyword':
                this.props.setSchemesFilter({ keyword: value });
                break;
            case 'address':
                this.props.setSchemesFilter({ address: value });
                break;
            default:
                break;
        }

    }

    // Toggle button, accepts three arguments
    // $value: Value of the toggle, usually true or false
    // $name: Conditional name, for example 'allowNullSize'
    handleToggleChange = (value, name) => {
        this.props.setSchemesFilter({ [name]: !value });
    }


    // Set state for input on slider change
    handleRangeChange = (category, value) => {
        switch (category) {
            case 'size':
                // this.setState({ sizeRangeValueMin: value.min });
                this.setState({ sizeRangeValueMax: value.max });
                break;
            default:
                break;
        }

    };

    // Set state for input on slider change
    handleRangeChangeComplete = (category, value) => {
        switch (category) {
            case 'size':
                this.props.setSchemesFilter({
                    size: {
                        min: value.min * 1000,
                        max: value.max * 1000,
                    }
                });
                break;
            default:
                break;
        }

    };

    // Used in tab navigation for search filters
    toggleFilters(tab) {
        let { view } = this.props;
        if (view.filterToShow !== tab) {
            this.props.setFilterToShow(tab, document.getElementById(tab).clientHeight)
        } else {
            this.props.setFilterToShow(null, 0);
        }
    }

    render() {
        let { props } = this;
        let { view, schemes } = props;
        let { filterToShow } = view;

        return (
            <Fragment>

                <Toolbar>
                    <FilterControls>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'keyword'}
                                onClick={() => { this.toggleFilters('keyword'); }}>
                                <span>Keyword</span>
                                <img src="/images/search-keyword.svg" alt="Keyword" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'size'}
                                onClick={() => { this.toggleFilters('size'); }}>
                                <span>Size</span>
                                <img src="/images/search-size.svg" alt="Size" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        <FilterControl>
                            <LightButton
                                small
                                onClick={() => this.props.resetSchemeFilters()}>
                                <img src="/images/Reset.svg" alt="reset" />
                                <span>Reset filters</span>
                            </LightButton>
                        </FilterControl>
                        <FilterControl>
                            <DownloadCSV csvData={schemes.csvData} name={`${this.props.company.name} - Schemes`} />
                        </FilterControl>
                    </FilterControls>

                </Toolbar>
                <SpringyFilters height={this.props.view.filterToShowHeight}>

                    <FadingFilter id="keyword" active={filterToShow === 'keyword'}>
                        <FilterGroup>
                            <FilterLabel>Filter by keyword</FilterLabel>
                            <FilterInput
                                // ref={(keywordInput) => { keywordInput && keywordInput.focus() }}
                                type="text"
                                list="propertySchemes"
                                value={schemes.filters.keyword}
                                name="keyword"
                                placeholder="Filter by name, address, scheme, postcode or use class"
                                onChange={(e) => this.handleInputChange(e.target.value, 'keyword')}
                                autoFocus
                                onFocus={(e) => { e.preventDefault(); document.body.scrollTop = 20; }} // To stop janking on iOS/Tablet
                                // eslint-disable-next-line
                                onKeyDown={(e) => { e.keyCode === 13 ? this.closeModal(e) : null }} // To minimize iOS keyboard on enter
                            />
                        </FilterGroup>
                    </FadingFilter>

                    <FadingFilter id="size" active={filterToShow === 'size'}>
                        {schemes.view.sizeRange.max > 0 &&
                            <FilterGroup>
                                <FilterLabel>Filter the size of the scheme</FilterLabel>
                                <FilterRange hideMinButton hideMinLabel doubleLabel>
                                    <InputRange
                                        allowSameValues={false}
                                        formatLabel={value => `${(value * 1000).toLocaleString('en-GB')} ft`}
                                        minValue={this.state.sizeRangeMin}
                                        maxValue={this.state.sizeRangeMax}
                                        value={{
                                            min: this.state.sizeRangeValueMin === null ? this.state.sizeRangeMin : this.state.sizeRangeValueMin,
                                            max: this.state.sizeRangeValueMax === null ? this.state.sizeRangeMax : this.state.sizeRangeValueMax,
                                        }}
                                        onChange={value => this.handleRangeChange('size', value)}
                                        onChangeComplete={value => this.handleRangeChangeComplete('size', value)} />
                                </FilterRange>
                            </FilterGroup>
                        }
                        <FilterGroup>
                            <FilterLabel>Show schemes without size information</FilterLabel>
                            <FilterToggle
                                value={schemes.filters.allowNullSize}
                                activeLabel={false}
                                inactiveLabel={false}
                                onToggle={(value) => {
                                    this.handleToggleChange(value, 'allowNullSize');
                                }}
                                colors={{
                                    active: {
                                        base: '#99BCAB'
                                    },
                                    inactive: {
                                        base: '#DADADA'
                                    }
                                }}
                                trackStyle={{
                                    height: '33px',
                                    borderColor: '#DADADA',
                                    border: '2px solid #DADADA'
                                }}
                                thumbAnimateRange={[1, 21]}
                                thumbStyle={{
                                    height: '33px',
                                    width: '33px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#706F6F',
                                    border: '2px solid #DADADA',
                                    left: '20px',
                                    boxShadow: 'none'
                                }}
                                thumbIcon={<img src={`/images/${schemes.filters.allowNullSize ? 'Check' : 'Close-Map-08'}.svg`} alt="toggle size" />} />
                        </FilterGroup>
                    </FadingFilter>

                </SpringyFilters>
            </Fragment>
        )
    }
}

const mapState = (state) => ({
    search: state.searchReducer,
    view: state.view,
    schemes: state.schemes,
    company: state.company,
})


const mapDispatch = (dispatch) => ({
    setViewMode(mode) { dispatch(setViewMode(mode)) },
    setFilterToShow(tab, height) { dispatch(setFilterToShow(tab, height)) },
    setPropertyViewPage(page) { dispatch(setPropertyViewPage(page)) },
    setViewTab(tab) { dispatch(setViewTab(tab)) },
    setDefaultFilters(state) { dispatch(setDefaultFilters(state)) },
    setSchemeViewPage(page) { dispatch(setSchemeViewPage(page)) },
    setSchemesPerPage(perPage, properties) { dispatch(setSchemesPerPage(perPage, properties)) },
    setSchemesFilter(filters) { dispatch(setSchemesFilter(filters)) },
    resetSchemeFilters() { dispatch(resetSchemeFilters()) },
})

export default connect(mapState, mapDispatch)(SchemeSearchResult);
