import styled from 'styled-components';

export const Dropdown = styled.div`
    position: relative;
    div {
        display: none;
        position: absolute;
        will-change: transform;
        top: 0px;
        left: 0px;
        transform: translate3d(-99px, 50px, 0px);
        display: ${props => props.isOpen ? 'block' : 'none'};
        z-index: 1000;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        h6 {
            display: block;
            padding: .5rem 1.5rem;
            margin-bottom: 0;
            font-size: .875rem;
            color: #6c757d;
            white-space: nowrap;
        }
        button {
            display: block;
            width: 100%;
            padding: .25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
        }
    }
`;

export const Avatar = styled.img`
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
`;