import styled from 'styled-components';
import { _BREAKS, breakOver } from './_shared';

// Fakes Bootstrap's "container-fluid" class
export const FluidWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0;
    position: relative;
`;

// Fakes Bootstrap's "container" class
export const Wrapper = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px)
        {max-width: 540px;}
        @media (min-width: 768px)
            {max-width: 720px;}
            @media (min-width: 992px)
                {max-width: 960px;}
                    @media (min-width: 1200px)
                    {max-width: 1140px;}
`;

// Fakes Bootstrap's "container" class
export const SmoothWrapper = styled.div`
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /* ${breakOver(_BREAKS.small)} */
    /* @media (min-width: 576px)
        {max-width: 540px;}
        @media (min-width: 768px)
            {max-width: 720px;}
            @media (min-width: 992px)
                {max-width: 960px;}
                    @media (min-width: 1200px)
                    {max-width: 1140px;} */
`;

// Fakes Boostraps "Row" class
export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;


export const Strapline = styled(Row)`
    background-color: #f2f2f2;
    height: 110px;
    align-items: center;
    padding: 0 15px;
    margin: 0;
    h2 {
        margin-bottom: 0;
        span {
            color: #eb5b56;
            font-weight: 800;
        }
    }
`;