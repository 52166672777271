import { SET_USER, REMOVE_USER, AUTH_LOGOUT } from '../_constants';

// The initial state contains the user object for things like avatars etc
const initialState = {
    id: null,
    type: "",
    email: "",
    title: null,
    forename: "",
    middle: null,
    surname: "",
    suffix: null,
    avatar_uri: "",
    avatar_id: null,
    status: "",
    registered: "",
    modified: "",
    last_login: "",
    n_logins: 0,
    error: null,
    message: "",
    loggedIn: false,
    sentMessages: []
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                ...action.payload,
                loggedIn: true
            }
        case REMOVE_USER:
            return {
                ...state,
                ...initialState
            }
        case AUTH_LOGOUT:
            return {
                ...state,
                loggedIn: false
            }

        default: return state
    }
}

export default userReducer;
