import { SET_PROPERTIES, SET_VISIBLE_PROPERTIES, SET_PROPERTIES_PER_PAGE, FILTER_AVAILABILITY, FILTER_MAX_SIZE, FILTER_NULL, FILTER_MAX_RENT, FILTER_KEYWORD, FILTER_RESET, FILTER_PROPERTIES, SET_PROPERTY_VIEW, SET_PROPERTY_VIEW_PAGE, SET_VIEW_PAGES, HIGHLIGHT_MAP_MARKER, CLEAR_MAP_MARKER, FLUSH_PROPERTIES } from '../_constants';
import { preparePropertyState, paginate, propertySearch, prepareViewState, createPaginationArray, generatePropertyCSVData } from '../components/_sharedFunctions';

const initialState = {
    properties: [], // list of properties that filter functions are applied from. Changeable.
    immutableProperties: [], // Response from API. DO NOT CHANGE THIS, use properties object instead.
    visibleProperties: [], // Array of visible properties based on pagination
    maxPropertyRent: 1,
    maxPropertySize: 1,
    compareProperties: [],
    pages: [],
    activePage: 1,
    perPage: 20,
    viewMode: 'list',
    showCompare: false,
    ready: false,
    loading: true,
    csvData: [],
    filters: {
        availability: "",
        category: "",
        keyword: "",
        allowNullRent: true,
        allowNullSize: true,
        minRent: 0,
        maxRent: 1,
        minSize: 0,
        maxSize: 1,
    },
    search: false,
    activeMapMarker: null
}

export function propertiesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PROPERTIES:
            let properties = preparePropertyState(action.payload);
            return {
                ...state,
                ...properties,
                csvData: generatePropertyCSVData(properties),
                loading: false,
                search: true
            }
        case SET_VISIBLE_PROPERTIES:
            return {
                ...state,
                visibleProperties: paginate(state.properties, action.payload, state.perPage),
                ready: true,
            }
        case SET_PROPERTIES_PER_PAGE:
            return {
                ...state,
                perPage: action.payload,
                visibleProperties: paginate(state.properties, 1, action.payload),
                ready: false,
            }
        case SET_PROPERTY_VIEW:
            return {
                ...state,
                ...prepareViewState(action.payload),
                ready: true,
            }
        case SET_PROPERTY_VIEW_PAGE:
            return {
                ...state,
                activePage: action.payload,
                ready: false,
            }
        case SET_VIEW_PAGES:
            return {
                ...state,
                pages: createPaginationArray(state.properties, action.payload),
                activePage: 1,
                ready: true,
            }
        case FILTER_AVAILABILITY:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    availability: action.payload
                },
                ready: false,
            }
        case FILTER_KEYWORD:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    keyword: action.payload
                },
                ready: false,
            }
        case FILTER_MAX_SIZE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    maxSize: action.payload
                },
                ready: false,
            }
        case FILTER_MAX_RENT:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    maxRent: action.payload
                },
                ready: false,
            }
        case FILTER_NULL:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                },
                ready: false,
            }
        case FILTER_RESET:
            return {
                ...state,
                ...preparePropertyState(state.immutableProperties),
                ready: true,
            }
        case FILTER_PROPERTIES:
            return {
                ...state,
                ...propertySearch(state.filters, state.immutableProperties, state.perPage),
                ready: true,
            }
        case HIGHLIGHT_MAP_MARKER:
            return {
                ...state,
                activeMapMarker: action.payload
            }
        case CLEAR_MAP_MARKER:
            return {
                ...state,
                activeMapMarker: null
            }

        case FLUSH_PROPERTIES:
            return initialState
        default: return state
    }
}

export default propertiesReducer;
