import React, { Component, Suspense, lazy } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import NavBar from './components/NavBar';
import { connect } from 'react-redux';
import { authSetTokens } from './_actions/auth';
import { CRLogoLoader } from './components/svg/CRLogo';
import Login from './pages/Login'
import Overview from './pages/Overview';
const Splash = lazy(() => import('./components/Splash'));

const FallbackContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 50px;
`;

class App extends Component {
  componentDidMount() {
    let userToAuth;
    if (localStorage.getItem('CR_USER_AUTH') && !this.props.user.loggedIn) {
      userToAuth = JSON.parse(localStorage.getItem('CR_USER_AUTH'))
      let currentDate = Date.now();
      let twoDays = 24 * 60 * 60;
      // To allow for a bit of a buffer, we'll check that the access_token still has 2 days
      // on it so that we don't run into any expiry issues
      if ((userToAuth.expires - currentDate) > twoDays)
        this.props.authSetTokens(userToAuth);
    }

  }
  render() {
    let NavBarWithRouter = withRouter(NavBar);
    return (
      <Router>
        <Suspense fallback={
          <FallbackContainer>
            <CRLogoLoader />
          </FallbackContainer>
        }>
          <NavBarWithRouter />
          <Switch>
            <Route exact path="/" component={Splash} />
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/overview/:id" component={Overview} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

const mapState = (state) => {
  return {
    user: state.user
  }
}

const mapDispatch = (dispatch) => {
  return {
    authSetTokens: (auth) => dispatch(authSetTokens(auth)),
  }
}

export default connect(mapState, mapDispatch)(App);
