import React from 'react';
import KeyHandler, { KEYDOWN } from 'react-key-handler';
import styled from 'styled-components';

const Pagination = styled.ul`
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
    li:first-of-type {
        button {
            border-radius: 10px 0px 0px 10px;
        }
    }
    li:last-of-type {
        button {
            border-radius: 0px 10px 10px 0px;
        }
    }
`;

const PaginationItem = styled.li`
    :hover {
        cursor: pointer;
    }
`;

const PaginationLink = styled.button`
    font-size: .8rem;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    :hover {
        cursor: pointer;
    }
`;

const ResultsPagination = ({activePage, pages, setViewPage}) => {

    // Sometimes the list of pages would be way too long, so we only want to show a slice of it
    // This function ensures only the a subset of the array is shown
    const generateVisiblePages = (additionalPagesToShow = 4) => {
        // Create an array of the next pages after the active page 
        let from, to, visible = [];
        if (pages < additionalPagesToShow + 1) {
            from = 1;
            to = pages;
        } else if ((activePage + additionalPagesToShow) / pages <= 1) {
            from = activePage;
            to = activePage + additionalPagesToShow;
        } else {
            from = pages - additionalPagesToShow;
            to = pages;
        }

        for(from; from <= to; from++) {
            visible.push(from);
        }

        return visible;
    }

    const ResultsPage = ({ page }) =>{
        let isActive = activePage === page ? true : false;
        return (
            <PaginationItem active={isActive} >
                {isActive? (
                    <PaginationLink disabled={true}>
                        {page}
                    </PaginationLink>
                ):(
                    <PaginationLink onClick={() => setViewPage(page)}>
                        {page}
                    </PaginationLink>
                )}
            </PaginationItem>
        )
    }

    const handleArrowPress = function (key) {
        switch (key) {
            case 'left':
                return activePage !== 1 ? setViewPage(activePage - 1) : null;
            case 'right':
                return activePage !== pages ? setViewPage(activePage + 1) : null;
            case 'down':
                return setViewPage(1);
            case 'up':
                return setViewPage(pages);
            default:
                return null;
        }
    }

    const checkIfDisabled = () => {
        if (activePage === pages)
            return true
        if (pages === 0)
            return true
        return false
    }

    return (
        <Pagination>
            <KeyHandler keyEventName={KEYDOWN} keyValue="ArrowLeft" onKeyHandle={() => handleArrowPress('left')} />
            <KeyHandler keyEventName={KEYDOWN} keyValue="ArrowRight" onKeyHandle={() => handleArrowPress('right')} />
            <KeyHandler keyEventName={KEYDOWN} keyValue="ArrowUp" onKeyHandle={() => handleArrowPress('up')} />
            <KeyHandler keyEventName={KEYDOWN} keyValue="ArrowDown" onKeyHandle={() => handleArrowPress('down')} />
            {pages >= 10 &&
                <PaginationItem className="bookend">
                    <PaginationLink disabled={activePage === 1 ? true : false} onClick={() => setViewPage(1)}>
                        <img src="/images/Double-Arrow-Left.svg" alt="<<" />
                    </PaginationLink>
                </PaginationItem>
            }
            <PaginationItem className="bookend">
                <PaginationLink disabled={activePage === 1 ? true : false} onClick={() => setViewPage(activePage - 1)}>
                    <img src="/images/Left-11.svg" alt="<" />
                </PaginationLink>
            </PaginationItem>
            {generateVisiblePages().map((page, key) => <ResultsPage page={page} key={key} />)}
            <PaginationItem className="bookend">
                <PaginationLink disabled={checkIfDisabled()} onClick={() => setViewPage(activePage + 1)}>
                    <img src="/images/Right-12.svg" alt=">" />
                </PaginationLink>
            </PaginationItem>
            {pages >= 10 &&
                <PaginationItem className="bookend">
                    <PaginationLink disabled={checkIfDisabled()} onClick={() => setViewPage(pages)}>
                        <img src="/images/Double-Arrow-Right.svg" alt=">>" />
                    </PaginationLink>
                </PaginationItem>
            }
        </Pagination>
    )
}

export default ResultsPagination;