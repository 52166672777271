import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ResultsPagination from './ResultsPagination';
import ResultsPaginationProperties from './ResultsPaginationProperties';
import {
    setPropertyViewPage,
} from '../_actions';
import {
    setSchemeViewPage,
} from '../_actions/schemes';
import {
    setRepsViewPage,
} from '../_actions/reps';

const Tray = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: max-content;
    justify-content: space-between;
    align-items: center;
    margin: .5rem 0;
`;

const ResultsShowing = styled.div``;

const ResultsTray = props => {
    let { schemes, properties, reps } = props;
    let totalResults, resultsVisible, resultsPerPage, activePage, pages, setViewPage
    switch (props.variant) {
        case 'properties':
            totalResults = properties.properties.length;
            resultsVisible = properties.visibleProperties.length;
            resultsPerPage = properties.perPage;
            activePage = properties.activePage;
            pages = properties.pages;
            setViewPage = props.setPropertyViewPage;
            break;
        case 'schemes':
            totalResults = schemes.view.totalResults;
            resultsVisible = schemes.view.visible.length;
            resultsPerPage = schemes.view.perPage;
            activePage = schemes.view.activePage;
            pages = schemes.view.pages;
            setViewPage = props.setSchemeViewPage;
            break;
        case 'reps':
            totalResults = reps.view.totalResults;
            resultsVisible = reps.view.visible.length;
            resultsPerPage = reps.view.perPage;
            activePage = reps.view.activePage;
            pages = reps.view.pages;
            setViewPage = props.setRepsViewPage;
            break;
        default:
            return false;
    }

    return (
        <Tray>
            <ResultsShowing>
                Showing results {((activePage - 1) * resultsPerPage) + 1} to {resultsVisible / resultsPerPage === 1 ? resultsPerPage * activePage : ((activePage - 1) * resultsPerPage) + (totalResults % resultsPerPage)} of {totalResults}
            </ResultsShowing>

            {(props.variant === 'properties') ? (
                <ResultsPaginationProperties
                    activePage={activePage}
                    pages={pages}
                    setViewPage={setViewPage}
                    />
            ) : (
                <ResultsPagination
                    activePage={activePage}
                    pages={pages}
                    setViewPage={setViewPage}
                    />
            )}

        </Tray>
    )
}

const mapState = (state) => {
    return {
        properties: state.propertiesReducer,
        schemes: state.schemes,
        reps: state.reps,
    }
}

const mapDispatch = (dispatch) => ({
    setPropertyViewPage(page) { dispatch(setPropertyViewPage(page)) },
    setSchemeViewPage(page) { dispatch(setSchemeViewPage(page)) },
    setRepsViewPage(page) { dispatch(setRepsViewPage(page)) },
})

export default connect(mapState, mapDispatch)(ResultsTray);
