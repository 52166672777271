import styled from 'styled-components';

export const Select = styled.select`
    display: block;
    height: 100%;
    color: #5B5A5A;
    border-radius: 10px;
    border: 2px solid #DADADA;
    min-width: 50px;
    padding: 2px 0 2px 5px;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* remove default arrow */
    background: url('/images/dropdown.svg') 87% / 20% no-repeat;   /* add custom arrow */
    :focus{
        border-radius:10px;
        outline: none;
        box-shadow: 0px 0px 2px #BABABA;
    }
`;

export const SelectWrapper = styled.div`
    position: relative;
    display: inline;
    :after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 30px;
        background-color: #dadada;
        display: block;
        width: 20px;
        height: 100%;
        border-radius: 0 10px 10px 0;
        z-index: -1;
    }
    :-ms-expand {
        display: none; /* hide the default arrow in ie10 and ie11 */
    }
`;