import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './_reducers';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from "./_epics/index";

const configureStore = () => {
    const epicMiddleware = createEpicMiddleware();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(epicMiddleware)
        )
    );

    epicMiddleware.run(rootEpic);

    return store;
}

export default configureStore;