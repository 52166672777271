import {
    SET_USER,
    REMOVE_USER,
    FILTER_PROPERTIES,
    SET_VIEW_PAGES,
    SET_PROPERTY_VIEW,
    FETCH_COMPANY,
    SET_COMPANY,
    SET_PROPERTY_VIEW_PAGE,
    FILTER_AVAILABILITY,
    FILTER_KEYWORD,
    FILTER_MAX_SIZE,
    FILTER_MAX_RENT,
    FILTER_NULL,
    FILTER_RESET,
    FILTER_UPDATE,
    SET_DEFAULT_FILTERS,
    SET_VIEW_TAB,
    SET_VIEW_MODE,
    SET_FILTER_TO_SHOW,
    FETCH_REPS,
    SET_REPS,
} from '../_constants';

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
})

export const fetchCompany = (id) => ({
    type: FETCH_COMPANY,
    payload: id
})

export const setCompany = (payload) => ({
    type: SET_COMPANY,
    payload
})

export const fetchReps = (id) => ({
    type: FETCH_REPS,
    payload: id
})

export const setReps = (payload) => ({
    type: SET_REPS,
    payload
})

export const filterProperties = () => ({
    type: FILTER_PROPERTIES
})

export const setPropertyView = (properties) => ({
    type: SET_PROPERTY_VIEW,
    payload: properties
})

export const setPropertyViewPage = (page) => ({
    type: SET_PROPERTY_VIEW_PAGE,
    payload: page
})

export const setViewPages = (perPage, properties) => ({
    type: SET_VIEW_PAGES,
    payload: perPage,
    properties
})

export const removeUser = (user) => ({
    type: REMOVE_USER
})

export const filterAvailability = (availability) => ({
    type: FILTER_AVAILABILITY,
    payload: availability
})

export const filterKeyword = (keyword) => ({
    type: FILTER_KEYWORD,
    payload: keyword
})

export const filterSize = (size) => ({
    type: FILTER_MAX_SIZE,
    payload: size
})

export const filterRent = (rent) => ({
    type: FILTER_MAX_RENT,
    payload: rent
})

export const filterNull = (nullValue) => ({
    type: FILTER_NULL,
    payload: nullValue
})

export const filterReset = (filters) => ({
    type: FILTER_RESET,
    payload: filters
})

export const filterUpdate = () => ({
    type: FILTER_UPDATE
})

export const setDefaultFilters = (filters) => ({
    type: SET_DEFAULT_FILTERS,
    payload: filters
})

export const setViewMode = (mode) => ({
    type: SET_VIEW_MODE,
    payload: mode
})

export const setViewTab = (tab) => ({
    type: SET_VIEW_TAB,
    payload: tab
})

export const setFilterToShow = (tab, height) => ({
    type: SET_FILTER_TO_SHOW,
    payload: {
        tab: tab,
        height: height,
    }
})
