export const CATEOGRY_NAMES = {
    BP: 'Business Parks',
    BUSINESSPARKS: 'Business Parks',
    HB: 'HB',
    HS: 'High Streets',
    HIGHSTREETS: 'High Streets',
    IN: 'Industrial Parks',
    INDUSTRIALPARKS: 'Industrial Parks',
    LE: 'Leisure Schemes',
    LEISURESCHEMES: 'Leisure Schemes',
    LO: 'Logistics',
    LOGISTICS: 'Logistics',
    OF: 'Offices',
    OFFICES: 'Offices',
    RW: 'Retail Warehouses',
    RETAILWAREHOUSES: 'Retail Warehouses',
    SC: 'Shopping centres',
    SHOPPINGCENTRES: 'Shopping centres',
    SP: 'Shopping parks',
    SHOPPINGPARKS: 'Shopping parks',
    TP: 'Trade Parks',
    TRADEPARKS: 'Trade Parks',
    WO: 'Workshops',
    WORKSHOPS: 'Workshops',
}

// export const CATEGORY_URIS = {
//     BP: 'BP',
//     BUSINESSPARKS: 'BP',
//     HB: 'HB',
//     HS: 'HS',
//     HIGHSTREETS: 'HS',
//     IN: 'IN',
//     INDUSTRIALPARKS: 'IN',
//     LE: 'LE',
//     LEISURESCHEMES: 'LE',
//     LO: 'LO',
//     LOGISTICS: 'LO',
//     OF: 'OF',
//     OFFICES: 'OF',
//     RW: 'RW',
//     RETAILWAREHOUSES: 'RW',
//     SC: 'SC',
//     SHOPPINGCENTRES: 'SC',
//     SP: 'SP',
//     SHOPPINGPARKS: 'SP',
//     TP: 'TP',
//     TRADEPARKS: 'TP',
//     WO: 'WO',
//     WORKSHOPS: 'WO',
// }

export const CATEGORY_CODES = {
    BP: 'BP',
    BUSINESSPARKS: 'BP',
    HB: 'HB',
    HS: 'HS',
    HIGHSTREETS: 'HS',
    IN: 'IN',
    INDUSTRIALPARKS: 'IN',
    LE: 'LE',
    LEISURESCHEMES: 'LE',
    LO: 'LO',
    LOGISTICS: 'LO',
    OF: 'OF',
    OFFICES: 'OF',
    RW: 'RW',
    RETAILWAREHOUSES: 'RW',
    SC: 'SC',
    SHOPPINGCENTRES: 'SC',
    SP: 'SP',
    SHOPPINGPARKS: 'SP',
    TP: 'TP',
    TRADEPARKS: 'TP',
    WO: 'WO',
    WORKSHOPS: 'WO',
}