import { FLUSH_REPS, SET_REPS_VIEW_PAGE, RESET_REPS_FILTERS, SET_REPS_FILTER, SET_REPS_PER_PAGE } from "../_constants";



export const setRepViewPage = (page) => ({
    type: SET_REPS_VIEW_PAGE,
    payload: page
})

export const setRepsPerPage = (payload, properties) => ({
    type: SET_REPS_PER_PAGE,
    payload,
    properties
})

export const setRepsFilter = (filters) => ({
    type: SET_REPS_FILTER,
    payload: filters
})

export const resetRepFilters = () => ({
    type: RESET_REPS_FILTERS,
})


export const setRepsViewPage = (page) => ({
    type: SET_REPS_VIEW_PAGE,
    payload: page
})

export const flushRepsData = () => ({
    type: FLUSH_REPS
})
