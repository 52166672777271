import styled from 'styled-components';

export const Form = styled.form`
    width: 100%;
`;

export const FormContainer = styled.div`
    max-width: 600px;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    border: 10px solid rgb(255,255,255);
    border: 10px solid rgba(255,255,255,0.3);
    background-color: rgba(255,255,255,0.3);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
    padding: 1em;
    text-align: left;
    label {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 5px;
    }
    .input-wrapper {
        position: relative;
        div {
            width: 50px;
            height: 50px;
            background-color: #F6F6F6;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            border-radius: 10px 0px 0px 10px;
            border: 1px solid #ced4da;
            -webkit-background-clip: padding-box; /* for Safari */
            background-clip: padding-box;
        }
        input {
            padding-left: 4em;
            height: 50px;
            border-radius: 10px;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: black;
                -webkit-box-shadow: 0 0 0px 1000px rgb(252, 252, 252) inset;
                transition: background-color 5000s ease-in-out 0s;
            }
            &:focus {
                border-color: transparent;
                box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25)
            }
        }
    }
`;

export const Label = styled.label`
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
    display: inline-block;
`;

export const FormGroup = styled.div`
    margin-bottom: 1rem;
`;

export const Input = styled.input`
    padding-left: 4em;
    height: 50px;
    border-radius: 10px;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000;
    color: #495057;
    background-color: #fff;
    :focus, :active {
        outline: none;
        box-shadow: 0 0 3pt 1pt #EC7474;
    }
`;