import { SET_REPS, SET_REPS_VIEW, APPLY_REPS_FILTERS, SET_REPS_VIEW_PAGE, SET_REPS_PER_PAGE, SET_REPS_FILTER, RESET_REPS_FILTERS, FLUSH_REPS } from "../_constants";
import { generateRepCSVData, } from '../components/_sharedFunctions';
// import { CATEOGRY_NAMES } from "../_constants/defs";

// User auth information is kept in state as well as localStorage. We also take the username (email address)
// Just so we're able to cross-examine user information where necessary
const initialState = {
    data: { // This is internal state, the UI shouldn't directly refer to this
        csvData: [],
        results: [], // list of schemes that filter functions are applied from. Changeable.
        immutableResults: [], // Response from API. DO NOT CHANGE THIS, use results array instead.
    },

    flags: { // Allows us to quickly determine whether to display a loading view
        loadingData: true,
        loadingView: false,
    },

    filters: { // User set constraints on data that is passed to view
        keyword: "",
    },

    view: {
        visible: [],
        pages: [], //
        activePage: 1,
        perPage: 20,
        totalResults: 0, // Value to show in UI after filtering
        viewMode: 'list',
    },
}

// Create an array of reps where each rep has all schemes/properties
// asigned to them
const assignSchemesToReps = (reps = []) => {
    // Check if anyting exists
    if (!Array.isArray(reps))
        reps = Object.values(reps)

        // Make sure we're only working with real people
    let allAgentReps = reps.filter(rep => rep.user === null ? false : true);
    // Temporary agents object
    let agents = {};

    allAgentReps.forEach(rep => {
        // Create a new agent if if they don't already exist
        if (!(rep.user.id in agents)) {
            agents[rep.user_id] = rep.user;
            agents[rep.user_id].csvData = [];
        }
        
        // Assign the scheme/property to the agent
        if (rep.subject && rep.subject.active && rep.subject.visible) {
            agents[rep.user_id].csvData.push({
                id: rep.subject.id,
                name: rep.subject.name,
                sector_code: rep.subject.sector_code,
                category_code: rep.subject.category_code,
                size_ft: rep.subject.size_ft,
                size_m: rep.subject.size_m,
                plot_ac: rep.subject.plot_ac,
                active: rep.subject.active,
                visible: rep.subject.visible,
                availability: rep.subject.availability,
                url: rep.subject.url,
                size_ft_min: rep.subject.size_ft_min,
                size_ft_max: rep.subject.size_ft_max,
                size_m_min: rep.subject.size_m_min,
                size_m_max: rep.subject.size_m_max,
                listing_type: rep.subject.listing_type,
                modified: rep.subject.modified,
                created: rep.subject.created,
            });
            // agents[rep.user_id].csvData.push(rep.subject);
        }
    })


    return ({
        immutableResults: Object.values(agents),
        csvData: generateRepCSVData(Object.values(agents))
    })
}

export function repsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_REPS:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: false,
                    loadingView: true,
                },
                data: {
                    ...state.data,
                    ...assignSchemesToReps(action.payload),
                },
            }

        case APPLY_REPS_FILTERS:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: false,
                },
                data: {
                    ...state.data,
                    results: action.payload,
                },
            }

        case SET_REPS_VIEW:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: false,
                },
                view: {
                    ...state.view,
                    ...action.payload,
                }
            }

        case SET_REPS_PER_PAGE:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: true,
                },
                view: {
                    ...state.view,
                    perPage: parseInt(action.payload, 10)
                }
            }

        case SET_REPS_VIEW_PAGE:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: true,
                },
                view: {
                    ...state.view,
                    activePage: parseInt(action.payload, 10)
                },
            }

        case SET_REPS_FILTER:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: true,
                },
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
                view: {
                    ...state.view,
                    activePage: 1,
                }
            }

        case RESET_REPS_FILTERS:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: true,
                },
                filters: {
                    ...initialState.filters,
                },
                view: {
                    ...state.view,
                    activePage: 1,
                }
            }

        case FLUSH_REPS:
            return initialState





        default: return state
    }
}

export default repsReducer;