// This file is just a place for storing the routes used by epics, in order to clean up that page and remove repetition

const baseRoute = process.env.REACT_APP_API;
export const blogRoute = 'https://blog-completelyretail.stage.coghub.net/wp-json/wp/v2/posts/?orderby=date&order=desc&per_page=2';

// Setup an inital state for queries to avoid merging errors
const emptyAPIquery = {
    route: false,
    id: false,
    page: false,
    pagesize: false,
    fields: [],
    filter: [],
    sort: [],
    with: [],
}

// Custom encoding that allows certain characters through
const customEncode = (string) => {
    return encodeURIComponent(string).replace(/%2B/g, '+').replace(/%2D/g, '-').replace(/%2C/g, ',')
}

// Core function for combining API parts into a usable uri
const createAPIquery = (defaults, options) => {
    // Merge defaults and options
    let route = options.route || defaults.route;
    let id = options.id || defaults.id;
    let pg = options.page || defaults.page;
    let pgSz = options.pagesize || defaults.pagesize;
    let env = options.envelope || defaults.envelope;
    let a = options.fields.concat(defaults.fields);
    let f = options.filter.concat(defaults.filter);
    let s = options.sort.concat(defaults.sort);
    let w = options.with.concat(defaults.with);
    let uri = '';

    // Create the uri from the merged parts
    if (route) uri += route;

    if (id) uri += id;

    uri += '?';

    if (pg) uri += `page=${pg}&`;

    if (pgSz) uri += `pagesize=${pgSz}&`;

    if (env) uri += `envelope=1&`;

    if (a.length > 0) a.forEach((value) => { uri += `a[]=${customEncode(value)}&` });

    if (f.length > 0) f.forEach((value) => { uri += `f[]=${customEncode(value)}&` });

    if (s.length > 0) s.forEach((value) => { uri += `s[]=${customEncode(value)}&` });

    if (w.length > 0) w.forEach((value) => { uri += `w[]=${customEncode(value)}&` });

    return baseRoute + uri;
}


export const API_CustomQuery = (options) => {
    return createAPIquery(emptyAPIquery, { ...emptyAPIquery, ...options })
}


export const API_SearchByLocationRoute = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: `${auth.loggedIn ? 'oauth' : 'public'}/property/`,
        pagesize: 9999,
        with: [
            'address.place',
            'scheme'
        ],
        filter: [
            'availability=To+Let,availability=Under+Offer',
            'category_code=HS,category_code=RW,category_code=SC',
            'active=1',
            'visible=1'
        ]
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// Get top level property info
export const API_GetProperty = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: `${auth.loggedIn ? 'oauth' : 'public'}/property/`,
        pagesize: 9999,
        filter: [
            'visible=1',
            'active=1',
            'availability=!Let'
        ],
        with: [
            'address.place',
            'scheme',
            'scheme_property.scheme'
        ]
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// Get top level property info
export const API_GetPropertyReduced = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: `${auth.loggedIn ? 'oauth' : 'public'}/property/`,
        pagesize: 9999,
        filter: [
            'visible=1',
            'active=1',
        ],
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// Get property information for PropertySingle component
export const API_GetPropertyDetail = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: 'public/property/',
        pagesize: 9999,
        filter: [
            'visible=1',
            'active=1',
        ],
        with: [
            'address.place',
            'feature.featureType',
            'portfolioActive',
            'resource.media',
            'rep.company.portfolioActive.market',
            'website'
        ],
    }

    // Request more priviliged information if auth is provided
    if (auth.loggedIn) {
        defaults.route = 'oauth/property/';
        defaults.with = defaults.with.concat([
            'accommodation',
            'rate',
            'rent',
            'rep.company.logoActive.media',
            'rep.company.contact',
            'rep.office.contact',
            'rep.user.contact',
            'rep.user.resourceActive',
            'rep.company.logoActive.media',
            'scheme_property.scheme',
        ]);
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// Find schemes based on keyword, used on the homepage
export const API_SearchScheme = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'public/scheme/',
        with: ['address.place']
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// Get scheme information
export const API_GetScheme = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: `${auth.loggedIn ? 'oauth' : 'public'}/scheme/`,
        pagesize: 9999,
        with: [
            'address.place',
            'scheme'
        ],
        filter: [
            'category_code=LE,category_code=RW,category_code=SC'
        ]
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })
}

// Get scheme information
export const API_GetSchemeReduced = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: `${auth.loggedIn ? 'oauth' : 'public'}/scheme/`,
        pagesize: 9999,
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })
}

// Get scheme information
export const API_GetSchemeDetail = (options, auth) => {

    let defaults = {
        ...emptyAPIquery,
        route: 'public/scheme/',
        with: [
            'address.place',
            'feature.featureType',
            'portfolioActive',
            'resource.media',
            'rep.company.portfolioActive.market',
            'rep.company.logoActive.media',
            'website'
        ],
    };

    // Request more priviliged information if auth is provided
    if (auth.loggedIn) {
        defaults.route = 'oauth/scheme/';
        defaults.with = defaults.with.concat([
            'rep.company.contact',
            'rep.office.contact',
            'rep.user.contact',
            'rep.user.resourceActive',
        ]);
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })
}


export const API_GetSchemeAvailability = options => {

    let defaults = {
        ...emptyAPIquery,
        pagesize: 1000,
        route: 'public/property/',
        with: ['scheme', 'tenant.contact'],
        sort: ['+name']
    }

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


export const API_GetPortfolio = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'public/portfolio/',
        pagesize: 9999,
        with: ['company']
    };

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


// CURRENTLY BROKEN (03/01/19)
export const API_GetTravelTimes = (location, mode) => {

    return `${baseRoute}oauth/gmaps/distance?origins[]=${location}&mode=${mode}`

}


export const API_setAuthToken = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'oauth/oauth/token/',
    };

    // return baseRoute + 'oauth/oauth/token/'
    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}


export const API_getUserAuth = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'oauth/login/verify',
    };

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}

export const API_resetUserPassword = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'public/user/resetpassword',
    };

    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}

export const API_getCompany = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'oauth/company/'
    };
    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}

export const API_getReps = options => {

    let defaults = {
        ...emptyAPIquery,
        route: 'oauth/rep/'
    };
    return createAPIquery(defaults, { ...emptyAPIquery, ...options })

}