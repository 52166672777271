import React from 'react';
import styled from 'styled-components';
import { CSVLink } from "react-csv";

const CSV = styled(CSVLink)`
    width: 120px;
    font-size: 14px;
    color: #444;
`

export const DownloadCSV = ({ csvData, name }) =>
    <CSV data={csvData} filename={`${name}.csv`} target="_blank">Download as CSV </CSV>
