import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError, withLatestFrom, mergeMap, } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { AUTH_LOGIN, AUTH_SET_TOKENS, AUTH_LOGIN_FAILED, AUTH_LOGOUT } from "../_constants";
import { API_setAuthToken, API_getUserAuth } from './_API-routes';

import { setUser, removeUser } from "../_actions";
import { authSetTokens } from "../_actions/auth";



// When a user logs in, fire off the auth event to generate the user auth and refresh tokens, as well as the final expiry date.
// We also save this to localStorage right now so that the application has access to it on subsequent hard-reloads
const setAuthTokens = (action$, state$) => action$.pipe(
    ofType(AUTH_LOGIN),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
        ajax.post(API_setAuthToken(), payload).pipe(
            map(({ response }) => {
                let userPayload = {
                    ...response,
                    expires: Date.now() + (response.expires_in * 1000),
                    username: payload.username
                }
                if (state.auth.rememberMe)
                    localStorage.setItem("CR_USER_AUTH", JSON.stringify({ ...userPayload, loggedIn: true }));
                return authSetTokens(userPayload);
            }),
            catchError(error => {
                if (error.status === 500)
                    return of({
                        type: AUTH_LOGIN_FAILED,
                        payload: {
                            error: 'invalid_credentials',
                            message: 'The user credentials were incorrect.',
                        },
                        error: true
                    })
                else
                    return of({
                        type: AUTH_LOGIN_FAILED,
                        payload: error.xhr.response,
                        error: true
                    })
            })
        )
    )
)

// When we get auth tokens, we can assume we want to fetch the user as well, so this does that
const setAuthUser = action$ => action$.pipe(
    ofType(AUTH_SET_TOKENS),
    mergeMap(({ payload }) => {
        return ajax({
            method: "POST",
            url: API_getUserAuth(),
            headers: { "Authorization": `${payload.token_type} ${payload.access_token}` }
        }).pipe(
            map(response => setUser(response.response.user))
        )
    })
)

// If a user logs out it resets the auth state, but we should also reset the user state
const removeUserObject = action$ => action$.ofType(AUTH_LOGOUT).pipe(
    map(() => removeUser())
)


let authEpics = [
    setAuthTokens,
    setAuthUser,
    removeUserObject,
];

export default authEpics;
