import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { useSpring } from 'react-spring';
import {
    setFilterToShow,
} from '../_actions';
import {
    setRepViewPage,
    setRepsPerPage,
    setRepsFilter,
    resetRepFilters,
} from '../_actions/reps';
import { LightButton } from './styled/Buttons';
import {
    FadingFilter,
    Link,
    FilterControl,
    FilterLabel,
    Toolbar,
    FilterControls,
    Filters,
    FilterGroup,
    FilterInput,
} from './styled/SearchResult';
import { DownloadCSV } from './DownloadCSV';


const SpringyFilters = props => {
    const { filterHeight } = useSpring({ filterHeight: props.height });

    return (
        <Filters style={{ height: filterHeight }}>
            {props.children}
        </Filters>
    )
}



class RepsSearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            sizeRangeMin: 0,
            sizeRangeMax: 1,
            sizeRangeValueMin: null,
            sizeRangeValueMax: null,
        };
        // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidUpdate() {
        // Update the filter & compare areas if the content has changed
        let { filterToShow, filterToShowHeight } = this.props.view;

        if (filterToShow && filterToShowHeight !== document.getElementById(filterToShow).clientHeight)
            this.props.setFilterToShow(filterToShow, document.getElementById(filterToShow).clientHeight)

    }

    // Set state for input on change
    handleInputChange = (value, category) => {
        // Remove foreign characters which could cause a crash
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        switch(category) {
            case 'keyword':
                this.props.setRepsFilter({keyword: value});
                break;

            default:
                break;
        }

    }

    // Toggle button, accepts three arguments
    // $value: Value of the toggle, usually true or false
    // $name: Conditional name, for example 'allowNullSize'
    handleToggleChange = (value, name) => {
        this.props.setRepsFilter({ [name]: !value });
    }


    // Set state for input on slider change
    handleRangeChange = (category, value) => {
        switch (category) {
            case 'size':
                // this.setState({ sizeRangeValueMin: value.min });
                this.setState({ sizeRangeValueMax: value.max });
                break;
            default:
                break;
        }

    };

    // Set state for input on slider change
    handleRangeChangeComplete = (category, value) => {
        switch (category) {
            case 'size':
                this.props.setRepsFilter({
                    size: {
                        min: value.min * 1000,
                        max: value.max * 1000,
                    }
                });
                break;
            default:
                break;
        }

    };

    // Used in tab navigation for search filters
    toggleFilters(tab) {
        let { view } = this.props;
        if (view.filterToShow !== tab) {
            this.props.setFilterToShow(tab, document.getElementById(tab).clientHeight)
        } else {
            this.props.setFilterToShow(null, 0);
        }
    }

    render() {
        let { props } = this;
        let { view, reps, company } = props;
        let { filterToShow } = view;

        return (
            <Fragment>

                <Toolbar>
                    <FilterControls>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'keyword'}
                                onClick={() => { this.toggleFilters('keyword'); }}>
                                <span>Keyword</span>
                                <img src="/images/search-keyword.svg" alt="Keyword" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        <FilterControl>
                            <LightButton
                                small
                                onClick={() => this.props.resetRepFilters()}>
                                <img src="/images/Reset.svg" alt="reset" />
                                <span>Reset filters</span>
                            </LightButton>
                        </FilterControl>

                    </FilterControls>
                    <DownloadCSV csvData={reps.data.csvData} name={`${company.name} - Reps`} />

                </Toolbar>
                <SpringyFilters height={filterToShow === 'keyword' ? this.props.view.filterToShowHeight : 0}>

                    <FadingFilter id="keyword" active={filterToShow === 'keyword'}>
                        <FilterGroup>
                            <FilterLabel>Filter by keyword</FilterLabel>
                            <FilterInput
                                // ref={(keywordInput) => { keywordInput && keywordInput.focus() }}
                                type="text"
                                list="propertyReps"
                                value={reps.filters.keyword}
                                name="keyword"
                                placeholder="Filter by name or email"
                                onChange={(e) => this.handleInputChange(e.target.value, 'keyword')}
                                autoFocus
                                onFocus={(e) => { e.preventDefault(); document.body.scrollTop = 20; }} // To stop janking on iOS/Tablet
                                // eslint-disable-next-line
                                onKeyDown={(e) => { e.keyCode === 13 ? this.closeModal(e) : null }} // To minimize iOS keyboard on enter
                            />
                        </FilterGroup>
                    </FadingFilter>

                </SpringyFilters>
            </Fragment>
        )
    }
}

const mapState = (state) => ({
    search: state.searchReducer,
    view: state.view,
    reps: state.reps,
    company: state.company
})


const mapDispatch = (dispatch) => ({
    // setViewMode(mode) { dispatch(setViewMode(mode)) },
    setFilterToShow(tab, height) { dispatch(setFilterToShow(tab, height))},
    // setPropertyViewPage(page) { dispatch(setPropertyViewPage(page)) },
    // setViewTab(tab) { dispatch(setViewTab(tab)) },
    setRepViewPage(page) { dispatch(setRepViewPage(page)) },
    setRepsPerPage(perPage, properties) { dispatch(setRepsPerPage(perPage, properties)) },
    setRepsFilter(filters) { dispatch(setRepsFilter(filters)) },
    resetRepFilters() { dispatch(resetRepFilters()) },
})

export default connect(mapState, mapDispatch)(RepsSearchResult);
