import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink } from '../components/styled/Nav';
import { connect } from 'react-redux';
import Properties from '../components/Properties';
import Schemes from '../components/Schemes';
import Reps from '../components/Reps';
import { Wrapper } from '../components/styled/Wrappers'
import styled from 'styled-components';
import {
    setFilterToShow,
    fetchCompany,
    fetchReps,
} from '../_actions';
import {
    fetchPropertiesForCompany,
    flushPropertiesData,
} from '../_actions/properties';
import {
    resetSchemeFilters,
    setVisibleCategory,
    fetchSchemesForCompany,
    flushSchemesData,
} from '../_actions/schemes';

const NavTray = styled(Nav)`
    max-width: 1200px;
    margin: auto;
    @media screen and (max-width: 500px) {
        padding: 0;
    }
    li:first-child a {
        border-radius: 15px 0 0 0;
    }
    li:last-child a {
        border-radius: 0 15px 0 0;
    }
    border-bottom: 5px solid #ebebeb;
`;

const NavTab = styled(NavLink)`
    cursor: pointer;
`;

class Overview extends Component {
    state = {
        activeTab: 'properties'
    }

    componentDidMount() {
        if (!this.props.schemes.search && !this.props.properties.search) {
            // Delay to avoid race condition of user validation (which must come before these calls)
            let { id } = this.props.match.params;
            setTimeout(() => {
                this.props.fetchPropertiesForCompany(id);
                this.props.fetchSchemesForCompany(id);
                this.props.fetchCompany(id);
                this.props.fetchReps(id);
            }, 1000);
        }
    }

    componentWillUnmount() {
        this.props.flushPropertiesData();
        this.props.flushSchemesData();
    }

    changeTab = (tab, category = '') => {
        if (tab === 'schemes')
            this.props.setVisibleCategory(category)

        this.setState({ activeTab: tab })
        this.props.setFilterToShow(null, 0);
    }

    render() {
        let { activeTab } = this.state;
        let { properties, schemes, reps, company } = this.props;

        return (
            <Fragment>
                <Wrapper>
                    <h1>Overview | {company.name}</h1>
                    <NavTray>
                        <NavItem><NavTab active={activeTab === 'properties'} onClick={() => this.changeTab('properties')}>Available Properties ({properties.properties.length})</NavTab></NavItem>
                        <NavItem><NavTab active={activeTab === 'schemes' && schemes.categories.current === 'RW'} onClick={() => this.changeTab('schemes', 'RW')}>Retail Parks ({schemes.categories.RW.length})</NavTab></NavItem>
                        <NavItem><NavTab active={activeTab === 'schemes' && schemes.categories.current === 'SC'} onClick={() => this.changeTab('schemes', 'SC')}>Shopping Centres ({schemes.categories.SC.length})</NavTab></NavItem>
                        <NavItem><NavTab active={activeTab === 'schemes' && schemes.categories.current === 'LE'} onClick={() => this.changeTab('schemes', 'LE')}>Leisure Parks ({schemes.categories.LE.length})</NavTab></NavItem>
                        <NavItem><NavTab active={activeTab === 'reps'} onClick={() => this.changeTab('reps')}>Reps ({reps.data.immutableResults.length})</NavTab></NavItem>
                    </NavTray>
                    {activeTab === 'properties' &&
                        <Properties />
                    }
                    {activeTab === 'schemes' &&
                        <Schemes />
                    }
                    {activeTab === 'reps' &&
                        <Reps />
                    }
                </Wrapper>
            </Fragment>
        );
    }
}

const mapState = (state) => {
    return {
        properties: state.propertiesReducer,
        schemes: state.schemes,
        user: state.user,
        company: state.company,
        reps: state.reps,
    }
}

const mapDispatch = (dispatch) => {
    return {
        setFilterToShow(tab, height) { dispatch(setFilterToShow(tab, height)) },
        fetchPropertiesForCompany: (id) => dispatch(fetchPropertiesForCompany(id)),
        fetchSchemesForCompany: (id) => dispatch(fetchSchemesForCompany(id)),
        fetchCompany: (id) => dispatch(fetchCompany(id)),
        fetchReps: (id) => dispatch(fetchReps(id)),
        resetSchemeFilters: () => dispatch(resetSchemeFilters()),
        setVisibleCategory: (category) => dispatch(setVisibleCategory(category)),
        flushPropertiesData: () => dispatch(flushPropertiesData()),
        flushSchemesData: () => dispatch(flushSchemesData()),
    }
}

export default connect(mapState, mapDispatch)(Overview);