import { FETCH_SCHEME, SET_SCHEME, FETCH_SCHEME_AVAILABILITY, SET_SCHEME_AVAILABILITY, CLEAR_SCHEME, SET_SCHEMES_PER_PAGE, SET_VISIBLE_SCHEMES, SET_SCHEMES, SEARCH_SCHEME, SEARCH_SCHEME_RESULTS, SEARCH_SCHEME_CLEAR, SET_ACTIVE_SCHEME_TAB, FETCH_SCHEMES_FOR_COMPANY, FETCH_SCHEMES_OUTLINE_COMPLETE, SET_SCHEMES_VIEW_PAGE, SET_SCHEME_VIEW, RESET_SCHEMES_FILTERS, SET_SCHEMES_FILTER, FETCH_SCHEMES_OUTLINE_START, SET_VISIBLE_CATEGORY, FLUSH_SCHEMES, } from "../_constants";


export const searchScheme = (keyword) => ({
    type: SEARCH_SCHEME,
    payload: keyword
})

export const searchSchemeResults = (schemes) => ({
    type: SEARCH_SCHEME_RESULTS,
    payload: schemes
})

export const searchSchemeClear = () => ({
    type: SEARCH_SCHEME_CLEAR
})

export const fetchScheme = (id, auth) => ({
    type: FETCH_SCHEME,
    payload: id,
    auth
})

export const setSchemes = (schemes) => ({
    type: SET_SCHEMES,
    payload: schemes
})

export const setSchemesPerPage = (payload, properties) => ({
    type: SET_SCHEMES_PER_PAGE,
    payload,
    properties
})

export const setVisibleSchemesByPage = (page, properties) => ({
    type: SET_VISIBLE_SCHEMES,
    payload: page,
    properties
})

export const fetchSchemeAvailability = (id) => ({
    type: FETCH_SCHEME_AVAILABILITY,
    payload: id,
})

export const fetchSchemesForCompany = (company, category) => ({
    type: FETCH_SCHEMES_FOR_COMPANY,
    payload: {
        company: company,
        category: category,
    },
})

export const setSchemeView = (scheme) => ({
    type: SET_SCHEME_VIEW,
    payload: scheme
})

// activeScheme actions
export const setScheme = (scheme) => ({
    type: SET_SCHEME,
    payload: scheme,
})

export const setSchemeAvailability = (properties) => ({
    type: SET_SCHEME_AVAILABILITY,
    payload: properties,
})

export const clearScheme = () => ({
    type: CLEAR_SCHEME
})

export const setActiveSchemeTab = (tool, tab) => ({
    type: SET_ACTIVE_SCHEME_TAB,
    payload: {
        key: tool,
        val: tab,
    }
})

// New Schemes stuff
export const fetchSchemesOutline = () => ({
    type: FETCH_SCHEMES_OUTLINE_START,
})

export const setSchemeViewPage = (page) => ({
    type: SET_SCHEMES_VIEW_PAGE,
    payload: page
})

export const fetchSchemesOutlineComplete = () => ({
    type: FETCH_SCHEMES_OUTLINE_COMPLETE
})

export const setSchemesFilter = (filters) => ({
    type: SET_SCHEMES_FILTER,
    payload: filters
})

export const resetSchemeFilters = () => ({
    type: RESET_SCHEMES_FILTERS
})

export const setVisibleCategory = (category) => ({
    type: SET_VISIBLE_CATEGORY,
    payload: category
})

export const flushSchemesData = () => ({
    type: FLUSH_SCHEMES
})
