// import { SET_SCHEMES, SET_VISIBLE_SCHEMES, SET_SCHEMES_PER_PAGE, FILTER_AVAILABILITY, FILTER_MAX_SIZE, FILTER_NULL, FILTER_MAX_RENT, FILTER_KEYWORD, FILTER_RESET, FILTER_SCHEMES, SET_SCHEME_VIEW, SET_SCHEME_VIEW_PAGE, SET_VIEW_PAGES, HIGHLIGHT_MAP_MARKER, CLEAR_MAP_MARKER, FETCH_SCHEMES_OUTLINE_CHUNK, FETCH_SCHEMES_OUTLINE_COMPLETE } from '../_constants';
// import { prepareSchemeState, paginate, schemeSearch, prepareViewState, createPaginationArray } from '../components/_sharedFunctions';
// import { stat } from 'fs';
import { FETCH_SCHEMES_OUTLINE_CHUNK, FETCH_SCHEMES_OUTLINE_COMPLETE, SET_SCHEMES_VIEW, APPLY_SCHEMES_FILTERS, SET_SCHEMES_VIEW_PAGE, SET_SCHEMES_PER_PAGE, FETCH_SCHEMES_OUTLINE_START, SET_SCHEMES, RESET_SCHEMES_FILTERS, SET_SCHEMES_FILTER, SET_VISIBLE_CATEGORY, FLUSH_SCHEMES, } from '../_constants';
import { getSizeRange, generateSchemeCSVData } from '../components/_sharedFunctions';

const initialState = {

    data: { // This is internal state, the UI shouldn't directly refer to this
        results: [], // list of schemes that filter functions are applied from. Changeable.
        immutableResults: [], // Response from API. DO NOT CHANGE THIS, use results object instead.
        pages: 0, // Serverside number of pages
        currentPage: 0, // Index of last page retrieved from the server
        perPage: 2000, // Number of results retrieved per chunk from server
        totalResults: 0, // Total serverside results
    },

    csvData: [],

    categories: {
        current: "",
        LE: [],
        RW: [],
        SC: [],
    },

    search: false,

    flags: { // Allows us to quickly determine whether to display a loading view
        loadingData: false,
        loadingView: false,
    },

    filters: { // User set constraints on data that is passed to view
        keyword: "",
        address: "",
        allowNullSize: true,
        size: {
            min: null,
            max: null,
        }
    },

    view: {
        visible: [],
        pages: [], //
        activePage: 1,
        perPage: 20,
        totalResults: 0, // Value to show in UI after filtering
        viewMode: 'list',
        compare: [], // schemes under comparison
        sizeRange: {
            min: 0,
            max: 0,
        },
    },
}


const sortSchemes = (schemes) => {
    let SC = [];
    let RW = [];
    let LE = [];
    let misc = [];
    schemes.map(scheme => {
        switch (scheme.category_code) {
            case 'SC':
                SC.push(scheme);
                break;
            case 'LE':
                LE.push(scheme);
                break;
            case 'RW':
                RW.push(scheme);
                break;

            default:
                misc.push(scheme);
        }
        return null;
    })
    return {
        SC, RW, LE
    }
}

export function schemes(state = initialState, action) {
    let combinedResults;
    switch (action.type) {
        case SET_SCHEMES:
            return {
                ...state,
                data: {
                    ...state.data,
                    immutableResults: action.payload
                },
                csvData: generateSchemeCSVData(action.payload),
                categories: sortSchemes(action.payload),
                search: true
            }
        case FETCH_SCHEMES_OUTLINE_START:
            return {
                ...state,
                data: {
                    ...state.data,
                    results: initialState.data.results,
                    immutableResults: initialState.data.immutableResults,
                    totalResults: initialState.data.totalResults,
                    pages: initialState.data.pages,
                    currentPage: initialState.data.currentPage,
                },
                // view: initialState.view
                view: {
                    ...state.view,
                    visible: initialState.view.visible,
                    pages: initialState.view.pages,
                    activePage: initialState.view.activePage,
                    perPage: initialState.view.perPage,
                    totalResults: initialState.view.totalResults,
                },
                flags: {
                    ...state.flags,
                    loadingData: true,
                }
            }

        case FETCH_SCHEMES_OUTLINE_CHUNK:
            combinedResults = [
                ...state.data.immutableResults,
                ...action.payload.results,
            ];
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: true,
                },
                data: {
                    ...state.data,
                    immutableResults: combinedResults,
                    totalResults: action.payload.totalResults || state.data.totalResults,
                    pages: action.payload.pages || state.data.pages,
                    currentPage: action.payload.currentPage || state.data.currentPage + 1,
                }
            }
        case FETCH_SCHEMES_OUTLINE_COMPLETE:
            combinedResults = [
                ...state.data.immutableResults,
                ...action.payload.results,
            ];
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: false,
                    loadingView: true,
                },
                data: {
                    ...state.data,
                    immutableResults: combinedResults,
                    totalResults: action.payload.totalResults || state.data.totalResults,
                    pages: action.payload.pages || state.data.pages,
                },
                filters: {
                    ...state.filters,
                    size: getSizeRange(combinedResults)
                },
                view: {
                    ...state.view,
                    sizeRange: getSizeRange(combinedResults)
                }
            }

        case APPLY_SCHEMES_FILTERS:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: false,
                },
                data: {
                    ...state.data,
                    results: action.payload,
                },
                // view: {
                //     ...state.view
                // }
            }

        case SET_SCHEMES_VIEW:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: false,
                },
                view: {
                    ...state.view,
                    ...action.payload,
                }
            }

        case SET_VISIBLE_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    current: action.payload,
                },
                filters: {
                    ...state.filters,
                    size: getSizeRange(state.categories[action.payload]),
                },
                view: {
                    ...state.view,
                    sizeRange: getSizeRange(state.categories[action.payload]),
                }
            }

        case SET_SCHEMES_PER_PAGE:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: true,
                },
                view: {
                    ...state.view,
                    perPage: parseInt(action.payload, 10)
                }
            }

        case SET_SCHEMES_VIEW_PAGE:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingView: true,
                },
                view: {
                    ...state.view,
                    activePage: parseInt(action.payload, 10)
                },
            }

        case SET_SCHEMES_FILTER:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: true,
                },
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
                view: {
                    ...state.view,
                    activePage: 1,
                }
            }

        case RESET_SCHEMES_FILTERS:
            return {
                ...state,
                flags: {
                    ...state.flags,
                    loadingData: true,
                },
                filters: {
                    ...initialState.filters,
                },
                view: {
                    ...state.view,
                    activePage: 1,
                }
            }

        case FLUSH_SCHEMES:
            return initialState


        default: return state
    }
}

export default schemes;
