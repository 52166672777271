import { SET_VIEW, SET_VIEW_MODE, SET_VIEW_PAGE, SET_VIEW_TAB, TOGGLE_SHOW_MAP, PAUSE_VIDEO, SET_FILTER_TO_SHOW } from '../_constants';
import { prepareViewState } from '../components/_sharedFunctions';

const initialState = {
    activeTab: null,
    filterToShow: null,
    filterToShowHeight: 0,
    viewMode: 'list', // View mode, can be 'list', 'grid', or 'map'
    pages: [], // Will eventually hold keys for amount of pages to be used in pagination
    activePage: 1, // Current, selected page in pagination
    showCompare: false,
    loading: true,
    mapVisible: true,
    homeVideoPaused: false
}

export function viewReducer(state = initialState, action) {
    switch (action.type) {

        case SET_VIEW:
            return {
                ...state,
                ...prepareViewState(action.payload)
            }

        case SET_VIEW_MODE:
            return {
                ...state,
                viewMode: action.payload
            }

        case SET_VIEW_PAGE:
            return {
                ...state,
                activePage: action.payload
            }
        case PAUSE_VIDEO:
            return {
                ...state,
                homeVideoPaused: action.payload
            }

        // case SET_VIEW_PAGES:
        //     return {
        //         ...state,
        //         pages: createPaginationArray(action.properties, action.payload),
        //         activePage: 1
        //     }

        case SET_VIEW_TAB:
            return {
                ...state,
                activeTab: action.payload
            }

        case SET_FILTER_TO_SHOW:
            return {
                ...state,
                filterToShow: action.payload.tab,
                filterToShowHeight: action.payload.height,
            }
        case TOGGLE_SHOW_MAP:
            return {
                ...state,
                mapVisible: action.payload
            }

        default: return state
    }
}

export default viewReducer;
