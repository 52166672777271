import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    background-color: white;
    thead {
        border: none;
        tr {
            background-image: linear-gradient(90deg, #e8336b 0%, #eb5e40 100%);
            color: white;
            font-weight: bold;
            border: none;
            td, th {
                vertical-align: baseline;
                padding: 0.75rem;
                border: none;
            }
        }
    }
    tbody {
        tr {
            :hover {
                background-color: ${props => props.hover ? 'rgba(0, 0, 0, 0.075)' : 'initial'}
            }
            td {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 1px solid #dee2e6;
                transition: background-color .2s ease;
                &:nth-of-type(odd) {
                    background-color: #FAFAFA;
                }
                &:nth-of-type(even) {
                    background-color: #F2F2F2;
                }
                &:first-of-type {
                    background-color: #FFFFFF;
                }
            }
            &:hover td {
                background-color: #FEF1EE;
            }
        }
    }

    p {
        margin-bottom: 0;
        font-size: .9rem;
    }

    .btn {
        font-size: .8rem;
    }
`;