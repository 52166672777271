import { AUTH_SET_TOKENS, AUTH_REMEMBER_TOGGLE, AUTH_LOGOUT, AUTH_LOGIN_FAILED } from "../_constants";

// User auth information is kept in state as well as localStorage. We also take the username (email address)
// Just so we're able to cross-examine user information where necessary
const initialState = {
    access_token: "",
    expires_in: null,
    expires: "",
    valid_from: null,
    refresh_token: "",
    token_type: "",
    loggedIn: false,
    username: "",
    pendingReset: false,
    resetSuccess: false,
    rememberMe: false,
    error: false,
    errorMessage: ""
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_SET_TOKENS:
            return {
                ...state,
                ...action.payload,
                loggedIn: true,
                error: false,
                errorMessage: ""
            }
        case AUTH_REMEMBER_TOGGLE:
            return {
                ...state,
                ...action.payload
            }
        case AUTH_LOGOUT:
            return {
                ...state,
                ...initialState
            }
        case AUTH_LOGIN_FAILED:
            return {
                ...state,
                ...action.payload
            }

        default: return state
    }
}

export default authReducer;