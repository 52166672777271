import { ajax } from 'rxjs/ajax';
import { map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { FETCH_REPS, SET_REPS, RESET_REPS_FILTERS, SET_REPS_FILTER, APPLY_REPS_FILTERS, SET_REPS_VIEW_PAGE, UPDATE_REPS_VIEW, SET_REPS_VIEW, SET_REPS_PER_PAGE } from '../_constants';
import { API_getReps } from './_API-routes';
import { createHeaders } from './_sharedFunctions';
import { setReps } from '../_actions';
import { filterReps, updateRepsView } from '../components/_sharedFunctions';
// import { updateSchemesView, filterSchemes } from '../components/_sharedFunctions';

// Fetch company info
const fetchRepsEp = (action$, state$) => action$.pipe(
    ofType(FETCH_REPS),
    withLatestFrom(state$),
    switchMap(([{ payload }, { auth }]) => {
        let query = {
            filter: [
                `company_id=${payload}`
            ],
            with: ['user.resourceActive.media', 'subject'],
            pagesize: 9999
        };
        console.log(API_getReps(query));
        return ajax.getJSON(API_getReps(query), createHeaders(auth))
    }),
    map(response => {
        return setReps(response)
    }
))

const updateRepsFilterEp = (action$, state$) => action$.pipe(
    ofType(
        SET_REPS,
        RESET_REPS_FILTERS,
        SET_REPS_FILTER,
        // SET_VISIBLE_CATEGORY,
    ),
    withLatestFrom(state$),
    map(([action, {reps}]) => {
        return ({
            type: APPLY_REPS_FILTERS,
            payload: filterReps(reps.data.immutableResults, reps.filters)
        })
    })
)

const updateRepsViewEp = (action$, state$) => action$.pipe(
    ofType(
        SET_REPS_PER_PAGE,
        SET_REPS_VIEW_PAGE,
        APPLY_REPS_FILTERS,
        UPDATE_REPS_VIEW,
    ),
    withLatestFrom(state$),
    map(([action, { reps }]) => {
        return ({
            type: SET_REPS_VIEW,
            payload: updateRepsView(reps.data.results, reps.view)
        })
    })
)


let repsEpics = [
    fetchRepsEp,
    updateRepsFilterEp,
    updateRepsViewEp,
];

export default repsEpics;