import React from 'react';
import { ActionButton } from './styled/Buttons';
import { Avatar } from './styled/Dropdown';
import styled from 'styled-components';

// const UserDropdown = styled(Dropdown)`
const UserDropdown = styled.div`
    position: relative;
`;

const UserDropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    display: ${props => props.isOpen ? 'grid' : 'none'};
    grid-auto-rows: min-content;
    justify-items: start;
    z-index: 1000;
    padding: 0.5rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    h6 {
        display: block;
        padding: .5rem 1rem;
        margin-bottom: 0;
        font-size: .875rem;
        color: #6c757d;
        white-space: nowrap;
    }
    hr {
        width: 75%;
    }
`;

const AvatarWrapper = styled.button`
    padding: 0!important;
    background: none!important;
    border: 0!important;
    margin-left: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    :focus, :active {
        outline: none;
        box-shadow: 0 0 3pt 1pt #EC7474;
    }
`;

const NavButton = styled(ActionButton)`
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    border: 0;
    margin: 0;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: #dadada;
`;

export default class NavBarUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        };
    }


    toggleVisibility = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    toggleBlur = (e) => {
        if (e.relatedTarget === null)
            this.toggleVisibility();
    }

    handleClick(e) {
        e.preventDefault();
        this.props.handleLogOut()
    }

    render() {
        let { avatar_uri, forename, surname } = this.props.user;
        let avatar = avatar_uri ? `https://completelyretail.co.uk/media/${avatar_uri}` : '/images/user-fallback.png';
        return (
            <UserDropdown isOpen={this.state.dropdownOpen} >
                <AvatarWrapper onClick={this.toggleVisibility} onBlur={(e) => this.toggleBlur(e)}>
                    <Avatar src={avatar} alt={`${forename} ${surname}`} />
                </AvatarWrapper>
                <UserDropdownMenu isOpen={this.state.dropdownOpen}>
                    <h6>Hi, {forename} {surname}</h6>
                    <hr />
                    <NavButton onClick={(e) => this.handleClick(e)}>Logout</NavButton>
                </UserDropdownMenu>
            </UserDropdown>
        );
    }
}