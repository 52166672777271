import React from 'react';
import styled from 'styled-components';
import { Table } from './styled/Tables';
import { ActionHref } from './styled/Buttons'
import SchemeSearchForm from './SchemeSearchForm'
import ResultsTray from './ResultsTray';
import { connect } from 'react-redux';
import { CRLogoLoader } from './svg/CRLogo';

const Loader = styled.div`
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 50px;
`;

const Schemes = props => {
    let { schemes } = props;

    if (schemes.flags.loadingView) {
        return (
            <Loader>
                <CRLogoLoader />
            </Loader>
        )
    } else {
        return (
            <div>
                <SchemeSearchForm />
                <ResultsTray variant="schemes" />

                <Table>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Town</td>
                            <td>Size (ft)</td>
                            <td>Website</td>
                            <td>Link</td>
                        </tr>
                    </thead>
                    <tbody>
                        {schemes.view.visible.map(scheme => (
                            <tr key={scheme.id}>
                                <td>{scheme.name ? scheme.name : '--'}</td>
                                <td>{scheme.address.place.name ? scheme.address.place.name : '--'}</td>
                                <td>{scheme.size_ft ? scheme.size_ft.toLocaleString() : '--'}</td>
                                <td>{scheme.website ? <ActionHref href={scheme.website} target="_blank" rel="noopener noreferrer">View</ActionHref> : '--'}</td>
                                <td><ActionHref href={`https://react-bootstrap.stage.coghub.net/scheme/${scheme.id}/${scheme.uri}`} target="_blank" rel="noopener noreferrer">View</ActionHref></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        schemes: state.schemes
    }
}

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Schemes);
