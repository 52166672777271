import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { useSpring } from 'react-spring';
import InputRange from 'react-input-range';
import { DownloadCSV } from "./DownloadCSV";
import {
    setViewTab,
    setFilterToShow,
    filterAvailability,
    filterSize,
    setDefaultFilters,
    filterNull,
    filterRent,
    filterKeyword,
    filterReset,
    filterUpdate,
    setViewMode,
    setPropertyViewPage,
} from '../_actions';
import { kFormatter } from './_sharedFunctions';
import { setPropertiesPerPage } from '../_actions/properties';
import { LightButton } from './styled/Buttons';
import {
    FadingFilter,
    Link,
    FilterControl,
    FilterLabel,
    Toolbar,
    FilterControls,
    Filters,
    FilterGroup,
    FilterSelectWrapper,
    FilterSelect,
    FilterInput,
    FilterToggle,
    FilterRange,
} from './styled/SearchResult';

const SpringyFilters = props => {
    const { filterHeight } = useSpring({ filterHeight: props.height });

    return (
        <Filters style={{ height: filterHeight }}>
            {props.children}
        </Filters>
    )
}



class PropertySearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            filterHeight: 0,
            compareHeight: 0,
        };
        // this.filterRef = React.createRef();
        this.compareRef = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate() {
        let { filterToShow, filterToShowHeight } = this.props.view;

        if (filterToShow && filterToShowHeight !== document.getElementById(filterToShow).clientHeight)
            this.props.setFilterToShow(filterToShow, document.getElementById(filterToShow).clientHeight)

    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    setViewState = (viewMode) => {
        if (viewMode !== this.props.view.viewMode)
            this.props.setViewMode(viewMode);
    }

    // Set state for input on change
    handleInputChange = (value) => {
        // Remove foreign characters which could cause a crash
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");

        this.props.filterKeyword(value);
    }

    // Toggle button, accepts three arguments
    // $value: Value of the toggle, usually true or false
    // $name: Conditional name, for example 'allowNullSize'
    handleToggleChange = (value, name) => {
        this.props.filterNull({ [name]: !value });
    }


    // Set state for input on slider change
    handleRangeChange = (category, value) => {
        switch (category) {
            case 'size':
                this.props.filterSize(value.max)
                break;
            case 'rent':
                this.props.filterRent(value.max)
                break;
            default:
                break;
        }

    };

    // Set state for input on slider change
    handleRangeChangeComplete = () => {
        this.props.filterUpdate();
    };

    resetSearch = (e) => {
        e.preventDefault();
        this.props.filterReset({
            availability: "",
            category: "",
            keyword: "",
            maxRent: this.props.properties.maxPropertyRent,
            maxSize: this.props.properties.maxPropertySize,
            allowNullRent: true,
            allowNullSize: true,
        })
    }

    // Used in tab navigation for search filters
    toggleFilters(tab) {
        let { view } = this.props;
        if (view.filterToShow !== tab) {
            this.props.setFilterToShow(tab, document.getElementById(tab).clientHeight)
        } else {
            this.props.setFilterToShow(null, 0);
        }
    }

    render() {

        let { props } = this;
        let { view, properties, company } = props;
        let { filterToShow } = view;

        let filters = props.properties.filters;
        let availabilityTabTitle = 'Availability';
        let rentTabTitle = 'Rent';
        let sizeTabTitle = 'Size';

        switch (filters.availability) {
            case 'available':
                availabilityTabTitle = 'Available';
                break;
            case 'under-offer':
                availabilityTabTitle = 'Under Offer';
                break;
            default:
                availabilityTabTitle = 'All Availability';
        }

        if (filters.maxRent) {
            rentTabTitle = `< £${kFormatter(filters.maxRent)} pa`;
        } else {
            rentTabTitle = `0 pa`;
        }

        if (filters.maxSize > 0) {
            sizeTabTitle = `< ${kFormatter(filters.maxSize)} ft`;
        } else {
            sizeTabTitle = `0 ft`
        }

        let maxRent = this.props.properties.maxPropertyRent;
        let maxSize = this.props.properties.maxPropertySize;

        return (
            <Fragment>
                <Toolbar>
                    <FilterControls>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'keyword'}
                                onClick={() => { this.toggleFilters('keyword'); }}>
                                <span> Keyword</span>
                                <img src="/images/search-keyword.svg" alt="Keyword" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'availability'}
                                onClick={() => { this.toggleFilters('availability'); }}>
                                <span>{availabilityTabTitle}</span>
                                <img src="/images/search-available.svg" alt="Availability" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        <FilterControl>
                            <Link
                                active={filterToShow === 'size'}
                                onClick={() => { this.toggleFilters('size'); }}>
                                <span>{sizeTabTitle}</span>
                                <img src="/images/search-size.svg" alt="Size" aria-hidden="true" />
                            </Link>
                        </FilterControl>

                        {filters.maxRent > 0 &&
                            <FilterControl>
                                <Link
                                    active={filterToShow === 'rent'}
                                    onClick={() => { this.toggleFilters('rent'); }}>
                                    <span>{rentTabTitle}</span>
                                    <img src="/images/search-rent.svg" alt="Rent" aria-hidden="true" />
                                </Link>
                            </FilterControl>
                        }

                        <FilterControl>
                            <LightButton
                                small
                                onClick={(e) => this.resetSearch(e)}>
                                <img src="/images/Reset.svg" alt="reset" />
                                <span>Reset filters</span>
                            </LightButton>
                        </FilterControl>

                    </FilterControls>
                    <DownloadCSV csvData={properties.csvData} name={`${company.name} - Properties`} />
                </Toolbar>
                <SpringyFilters height={this.props.view.filterToShowHeight}>

                    <FadingFilter id="keyword" active={filterToShow === 'keyword'}>
                        <FilterGroup>
                            <FilterLabel>Filter results by keyword</FilterLabel>
                            <FilterInput
                                ref={(input) => { input && input.focus() }}
                                type="text"
                                list="propertySchemes"
                                value={filters.keyword}
                                name="keyword"
                                placeholder="Filter by name, address, scheme, postcode or use class"
                                onChange={(e) => this.handleInputChange(e.target.value)}
                                autoFocus
                                onFocus={(e) => { e.preventDefault(); document.body.scrollTop = 20; }} // To stop janking on iOS/Tablet
                                // eslint-disable-next-line
                                onKeyDown={(e) => { e.keyCode === 13 ? this.closeModal(e) : null }} // To minimize iOS keyboard on enter
                            />
                        </FilterGroup>
                    </FadingFilter>

                    <FadingFilter id="availability" active={filterToShow === 'availability'}>
                        <FilterGroup>
                            <FilterLabel>Select the availability you'd like to filter your results by</FilterLabel>
                            <FilterSelectWrapper>
                                <FilterSelect name="availability" onChange={(e) => this.props.filterAvailability(e.target.value)} value={filters.availability}>
                                    <option value="all">All</option>
                                    <option value="available">Available</option>
                                    <option value="under-offer">Under Offer</option>
                                </FilterSelect>
                            </FilterSelectWrapper>
                        </FilterGroup>
                    </FadingFilter>

                    <FadingFilter id="size" active={filterToShow === 'size'}>
                        {maxSize > 0 &&
                            <FilterGroup>
                                <FilterLabel>Filter the size of the property</FilterLabel>
                                <FilterRange hideMinButton hideMinLabel doubleLabel>
                                    <InputRange
                                        allowSameValues={false}
                                        formatLabel={value => `${(value).toLocaleString('en-GB')} ft`}
                                        minValue={0}
                                        maxValue={properties.maxPropertySize}
                                        value={{
                                            min: properties.filters.minSize ? properties.filters.minSize : 0,
                                            max: properties.filters.maxSize ? properties.filters.maxSize : properties.maxPropertySize,
                                        }}
                                        onChange={value => this.handleRangeChange('size', value)}
                                        onChangeComplete={value => this.handleRangeChangeComplete('size', value)} />
                                </FilterRange>
                            </FilterGroup>
                        }
                        <FilterGroup>
                            <FilterLabel>Show properties without size information</FilterLabel>
                            <FilterToggle
                                value={filters.allowNullSize}
                                activeLabel={false}
                                inactiveLabel={false}
                                onToggle={(value) => {
                                    this.handleToggleChange(value, 'allowNullSize');
                                }}
                                colors={{
                                    active: {
                                        base: '#99BCAB'
                                    },
                                    inactive: {
                                        base: '#DADADA'
                                    }
                                }}
                                trackStyle={{
                                    height: '33px',
                                    borderColor: '#DADADA',
                                    border: '2px solid #DADADA'
                                }}
                                thumbAnimateRange={[1, 21]}
                                thumbStyle={{
                                    height: '33px',
                                    width: '33px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#706F6F',
                                    border: '2px solid #DADADA',
                                    left: '20px',
                                    boxShadow: 'none'
                                }}
                                thumbIcon={<img src={`/images/${filters.allowNullSize ? 'Check' : 'Close-Map-08'}.svg`} alt="toggle size" />} />
                        </FilterGroup>
                    </FadingFilter>

                    <FadingFilter id="rent" active={filterToShow === 'rent'}>
                        {maxRent > 0 &&
                            <FilterGroup>
                                <FilterLabel>Filter the price of the property</FilterLabel>
                                <FilterRange hideMinButton hideMinLabel doubleLabel>
                                    <InputRange
                                        allowSameValues={false}
                                        formatLabel={value => `£${(value).toLocaleString('en-GB')} pa`}
                                        minValue={0}
                                        maxValue={properties.maxPropertyRent}
                                        value={{
                                            min: properties.filters.minRent ? properties.filters.minRent : 0,
                                            max: properties.filters.maxRent ? properties.filters.maxRent : properties.maxPropertyRent,
                                        }}
                                        onChange={value => this.handleRangeChange('rent', value)}
                                        onChangeComplete={value => this.handleRangeChangeComplete('rent', value)} />
                                </FilterRange>
                            </FilterGroup>
                        }
                        <FilterGroup>
                            <FilterLabel>Show properties without rent information </FilterLabel>
                            <FilterToggle
                                value={filters.allowNullRent}
                                activeLabel={false}
                                inactiveLabel={false}
                                onToggle={(value) => {
                                    this.handleToggleChange(value, 'allowNullRent');
                                }}
                                colors={{
                                    active: {
                                        base: '#99BCAB'
                                    },
                                    inactive: {
                                        base: '#DADADA'
                                    }
                                }}
                                trackStyle={{
                                    height: '33px',
                                    borderColor: '#DADADA',
                                    border: '2px solid #DADADA'
                                }}
                                thumbAnimateRange={[1, 21]}
                                thumbStyle={{
                                    height: '33px',
                                    width: '33px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#706F6F',
                                    border: '2px solid #DADADA',
                                    left: '20px',
                                    boxShadow: 'none'
                                }}
                                thumbIcon={<img src={`/images/${filters.allowNullRent ? 'Check' : 'Close-Map-08'}.svg`} alt="toggle size" />} />
                        </FilterGroup>
                    </FadingFilter>
                </SpringyFilters>
            </Fragment>
        )
    }
}

const mapState = (state) => ({
    search: state.searchReducer,
    view: state.view,
    properties: state.propertiesReducer,
    company: state.company,
})


const mapDispatch = (dispatch) => ({
    setViewMode(mode) { dispatch(setViewMode(mode)) },
    setFilterToShow(tab, height) { dispatch(setFilterToShow(tab, height))},
    setPropertyViewPage(page) { dispatch(setPropertyViewPage(page)) },
    setPropertiesPerPage(perPage, properties) { dispatch(setPropertiesPerPage(perPage, properties)) },
    setViewTab(tab) { dispatch(setViewTab(tab)) },
    filterAvailability(category) { dispatch(filterAvailability(category)) },
    filterKeyword(keyword) { dispatch(filterKeyword(keyword)) },
    filterSize(size) { dispatch(filterSize(size)) },
    filterRent(rent) { dispatch(filterRent(rent)) },
    filterNull(state) { dispatch(filterNull(state)) },
    filterReset(state) { dispatch(filterReset(state)) },
    filterUpdate() { dispatch(filterUpdate()) },
    setDefaultFilters(state) { dispatch(setDefaultFilters(state)) },
})

export default connect(mapState, mapDispatch)(PropertySearchResult);
