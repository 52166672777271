import styled from 'styled-components';

export const Badge = styled.div`
    display: inline-block;
    padding: ${props => props.padding ? props.padding : '0.25em 0.4em'};
    font-size: 75%;
    font-weight: ${props => props.fontWeight ? props.padding : '700'};
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
    color: ${props => props.color ? props.color : 'initial'};
    ${
    props => props.sponsored ? `border: 1px solid #eb5b56; background-color: white` : ''
    }
`;

export const GradientBadge = styled(Badge)`
    background-image: ${props => !props.bgColor ? `linear-gradient(90deg, rgba(232, 51, 107, 1) 0%, rgba(235, 94, 64, 1) 100%)` : 'none'};
    color: white;
`;

export const SponsoredBadge = styled(Badge)`
    font-weight: 500;
    background-image: linear-gradient(90deg,#e8336b 0%,#eb5e40 100%);
    color: #ffffff;
`;