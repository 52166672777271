import { SET_COMPANY } from "../_constants";

// User auth information is kept in state as well as localStorage. We also take the username (email address)
// Just so we're able to cross-examine user information where necessary
const initialState = {
    id: null,
    name: "",
    website: "",
    logo_uri: "",
    logo_id: null,
    active: 1,
}

export function company(state = initialState, action) {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                ...action.payload,
            }

        default: return state
    }
}

export default company;