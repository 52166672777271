import styled from 'styled-components';

export const Alert = styled.div`
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
`;

export const AlertDanger = styled(Alert)`
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
`;

export const AlertSuccess = styled(Alert)`
    color: #fff;
    background-color: green;
    border-color: green;
`;