import { combineReducers } from 'redux'
import propertiesReducer from './properties';
import schemes from './schemes';
import user from './user';
import auth from './auth';
import view from './view';
import reps from './reps';
import company from './company';

export default combineReducers({
    propertiesReducer,
    schemes,
    user,
    auth,
    company,
    view,
    reps,
})
